import Tag from "Components/atoms/Tag";
import { Map } from "google-maps-react";
import { useDispatch, useSelector } from "react-redux";
import {
  Capitalize,
  IMAGES,
  ROUTE_CONSTANTS,
  STRINGS,
  titleCase,
} from "Shared";
import Card from "Components/atoms/Cards";
import "./style.scss";
import { useHistory, useParams } from "react-router";
import {
  approveImage,
  deletePhotos,
  deleteReview,
  deleteTrail,
  deleteUserActivity,
  denyImage,
  getTrail,
  getTrailByLanguage,
  getUserActivities,
  set_current_userActivity,
  uploadMultipleImages,
} from "Redux/Actions/commonCRUD";
import { withSnackbar } from "notistack";
import ServerImage from "Components/atoms/Server_Image";
import { width } from "@mui/system";
import StarRatings from "react-star-ratings";
import moment from "moment";
import { resendOtp, setMultipleImages, sortImage } from "Redux/Actions/Auth";
import { useEffect, useState } from "react";
import MultipleImageUploader from "Components/atoms/MultipleImageUploader";
import { ContactSupportOutlined } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import { Select } from "@mui/material";
import Multi_Select_Dropdown from "Components/atoms/Multi_Select_Dropdown";
import SingleSelectDropdown from "Components/atoms/SingleSelectDropdown";
import CustomModal from "Components/atoms/Modal";
import Table from "Components/atoms/Table";

function Trail_Info(props) {
  const trail_data = useSelector((state) => state.current_trail.current_trail);
  const userActivity = useSelector(
    (state) => state.userActivity.UserActivities
  );

  const tag_selected_style = {
    background: "#9dbf1b",
    color: "black",
    border: "1px solid #E9550C",
  };

  const equipments = trail_data?.equipment || [];
  const placesToStop = trail_data?.placesToStop || [];
  const repairPoints = trail_data?.bikesRepair || [];
  const publicTransport = trail_data?.publicTransport || [];
  const garages = trail_data?.garages || [];
  const history = useHistory();
  const [language, setLanguage] = useState({ value: "en", label: "English" });
  const [fileUploadModal, setFileUploadModal] = useState(false);

  const dispatch = useDispatch();
  const dataSources = useSelector(
    (state) => state.multipleImages.multiple_images
  );
  const [activity, setActivity] = useState([]);
  const [loader, setLoader] = useState(0);
  const params = useParams();

  const toggleFileUploadModalView = () => {
    setFileUploadModal((value) => !value);
  };

  const deleteHandler = () => {
    dispatch(
      deleteTrail(trail_data?._id, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          history.push(ROUTE_CONSTANTS.ADMIN_TRAILS);
        }
      })
    );
  };

  const imageSortOptions = [
    { value: 3, label: "Both admin and user" },
    { value: 2, label: "Only admin" },
    { value: 1, label: "Only user" },
  ];

  // const [visible, setVisible] = useState(false);
  // const handleSetVisible = () => {
  //   setVisible(true);
  // };
  // const hideModal = () => {
  //   setVisible(false);
  // };
  // const onUpload = (data) => {
  //   setImages(data);
  // };

  // const onSelect = (data) => {
  //   console.log("Select files", data);
  // };
  // const onRemove = (index) => {
  //   let arr = [...sources];
  //   arr.splice(index, 1);
  //   setSources(arr);
  // };

  // const approveHandler = (id) => {
  //   setLoader(true);
  //   dispatch(
  //     approveImage(id, (message, type) => {
  //       setLoader(false);
  //       props.enqueueSnackbar(message, {
  //         variant: type,
  //         autoHideDuration: 2000,
  //         anchorOrigin: {
  //           vertical: "top",
  //           horizontal: "center",
  //         },
  //       });
  //       if (type == "success") {
  //         dispatch(getTrail(trail_data?._id));
  //       }
  //     })
  //   );
  // };

  // const denyHandler = (id) => {
  //   dispatch(
  //     denyImage(id, (message, type) => {
  //       props.enqueueSnackbar(message, {
  //         variant: type,
  //         autoHideDuration: 2000,
  //         anchorOrigin: {
  //           vertical: "top",
  //           horizontal: "center",
  //         },
  //       });
  //       if (type == "success") {
  //         dispatch(getTrail(trail_data?._id));
  //       }
  //     })
  //   );
  // };

  const imageSortHandler = (val) => {
    let data = {
      id: trail_data?._id,
      data: val?.value,
    };
    dispatch(
      sortImage(data, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      })
    );
  };

  const uploadImageHandler = () => {
    if (dataSources?.length) {
      let data = {
        trailId: trail_data?._id,
        imageUrl: dataSources?.map((value) => value?.fileUrl),
      };
      dispatch(
        uploadMultipleImages(data, (message, type) => {
          props.enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
          if (type == "success") {
            dispatch(getTrail(trail_data?._id));
            dispatch(setMultipleImages([]));
            // setImages([]);
          }
        })
      );
    }
  };

  const [defaultImageSort, setDefaultSortValue] = useState([]);
  useEffect(() => {
    setActivity(trail_data?.activity?.filter((data) => data.isAdded === true));
  }, [trail_data]);

  const review_delete_handler = (id) => {
    dispatch(
      deleteReview(id, (message, type) => {
        dispatch(getTrail(trail_data?._id));
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      })
    );
  };

  const snackbarHandler = (message, type) => {
    props.enqueueSnackbar(message, {
      variant: type,
      autoHideDuration: 2000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
    if (type == "success") {
      toggleFileUploadModalView();
      dispatch(getTrail(trail_data?._id));
    }
  };

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "fr", label: "French" },
    { value: "es", label: "Spanish" },
    { value: "de", label: "German" },
  ];

  const photos_delete_handler = (id) => {
    dispatch(
      deletePhotos(id, (message, type) => {
        dispatch(getTrail(trail_data?._id));
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      })
    );
  };

  useEffect(() => {
    setDefaultSortValue(
      imageSortOptions?.filter(
        (value) => value.value === trail_data?.imagesSort
      )
    );
  }, [trail_data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (language.value) {
      let data = {
        trailId: trail_data?._id,
        language: language.value,
      };
      dispatch(getTrailByLanguage(data));
      dispatch(getUserActivities(data));
    }
  }, [language]);

  const userActivity_Delete_Handler = (id) => {
    // console.log(id)
    let data = {
      id: id,
    };
    dispatch(
      deleteUserActivity(data, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          let data = {
            trailId: trail_data?._id,
            language: language.value,
          };
          dispatch(getTrailByLanguage(data));
          dispatch(getUserActivities(data));
        }
      })
    );
  };
  const userActivity_View_Handler = (id, data) => {
    // console.log(data, "userActivityData")
    dispatch(set_current_userActivity(data));
    history.push(ROUTE_CONSTANTS.VIEW_USERACTIVITY);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h4 className="h4">Trails</h4>
        <CustomModal
          isOpen={fileUploadModal}
          handleToggle={toggleFileUploadModalView}
        >
          <div className="modal-header justify-content-center">
            <h3 className="h4">Upload Photos</h3>
          </div>

          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <MultipleImageUploader
                    name="TrailImages"
                    placeholder=""
                    label="Upload File"
                    setFieldValue={("TrailImages", props.setFieldValue)}
                    id="trail"
                    showmap={true}
                    clickHandler={(message, type) =>
                      snackbarHandler(message, type)
                    }
                  />
                  {/* {error ? (
                          <span className="error">
                            This is a required field
                          </span>
                        ) : null} */}
                </div>
              </div>
            </div>
          </div>
        </CustomModal>

        {/* <div className="col-md-4">
          <div className="form-group">
            <label>Language</label>
            <SingleSelectDropdown
              //  id={defaultSortValue[0]?.value}
              options={languageOptions}
              defaultValue={languageOptions[0]}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              changeHandler={(e) => {
                setLanguage(e);
              }}
            />
            <label>This sorting would reflect on App side</label>
          </div>
        </div> */}
        <div className="col_rht">
          {language.value === "en" ? (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => history.push(ROUTE_CONSTANTS.EDIT_TRAIL)}
            >
              Edit
            </button>
          ) : null}
          <button
            className="btn btn-sm btn-cancel"
            onClick={() => deleteHandler()}
          >
            Delete
          </button>
        </div>
      </div>

      {Object.keys(trail_data).length ? (
        <>
          <div className="photos_list">
            <div className="photo_items">
              <figure>
                <ServerImage url={trail_data.trailImage} />
              </figure>
            </div>
          </div>

          <div className="basic_info">
            <h4>Trail Basic Info</h4>
            <div className="row">
              <div className="col-md-4">
                <div className="info_block">
                  <label>{STRINGS.NAME}</label>
                  <span>{Capitalize(trail_data?.trailName)}</span>
                </div>
              </div>

              {/* <div className="col-md-4">
                <div className="info_block">
                  <label>{STRINGS.TRAIL_COUNTRY}</label>
                  <span>{Capitalize(trail_data?.trailCountry)}</span>
                </div>
              </div>

              <div className="col-md-4">
                <div className="info_block">
                  <label>{STRINGS.STATE}</label>
                  <span>{Capitalize(trail_data?.trailState)}</span>
                </div>
              </div>

              <div className="col-md-4">
                <div className="info_block">
                  <label>{STRINGS.TRAIL_CITY}</label>
                  <span>{Capitalize(trail_data?.trailCity)}</span>
                </div>
              </div> */}

              <div className="col-md-12">
                <div className="info_block">
                  <label>{STRINGS.DESCRIPTION}</label>
                  <p>{trail_data?.trailDescription}</p>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <fieldset>
            <legend>{STRINGS.TRAIL_TYPE}</legend>
            <div className="basic_info">
              {/* <h4>{STRINGS.TRAIL_TYPE}</h4> */}
              <div className="row">
                <div className="col-md-4">
                  <div className="info_block">
                    <label>{STRINGS.TRAIL_CATEGORY_TYPE}</label>
                    <span>{Capitalize(trail_data?.trailCity)}</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="info_block">
                    <label>Terrain Type</label>
                    <span>{trail_data?.terrainType ?? "----"}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="info_block">
                    <label>{STRINGS.TRAIL_DIFFICULTY_LEVEL}</label>
                    <span>{titleCase(trail_data?.difficultyLevel?.name)}</span>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          {/* <div> */}

          <fieldset>
            <legend>Trail Distance/Time</legend>
            <div className="basic_info">
              {/* <h4>Trail Distance/Time</h4> */}

              <div className="row">
                <div className="col-md-4">
                  <div className="info_block">
                    <label>{STRINGS.DISTANCE}</label>
                    <span>{`${trail_data?.trailDistance} KM`}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="info_block">
                    <label>{STRINGS.MINIMUM_TIME}</label>
                    <span>{`${trail_data?.minimumTime} hrs `}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="info_block">
                    <label>{STRINGS.MAXIMUM_TIME}</label>
                    <span>{`${trail_data?.maximumTime} hrs `}</span>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Tracking Info</legend>
            <div className="basic_info">
              <div className="row">
                <div className="col-md-4">
                  <div className="info_block">
                    <label>{STRINGS.NEAREST_TOWN}</label>
                    <span className="content">
                      {titleCase(trail_data?.nearestTown)}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="info_block">
                    <label>{STRINGS.TRAIL_START_POINT}</label>
                    <span className="content">
                      {titleCase(trail_data?.startPoint?.description)}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="info_block">
                    <label>{STRINGS.TRAIL_TYPE}</label>
                    <span className="content">
                      {trail_data?.trailType
                        ? titleCase(trail_data?.trailType[0]?.name)
                        : "----"}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="info_block">
                    <label>{STRINGS.EQUIPMENT}</label>
                    <span className="content">
                      {Capitalize(trail_data?.trailEquipments)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Activity</legend>
            <div className="d-flex active_tags">
              {/* <div className="activity"> */}
              {/* <h3>Activity</h3> */}
              {activity?.map((value, tagIndex) => (
                <Tag tag_unselected_style={tag_selected_style} {...value} />
              ))}
            </div>
          </fieldset>

          <fieldset>
            <legend>Other Facilities</legend>

            <div className="equipments">
              <div className="row">
                <div className="col-md-12">
                  {equipments.length ? <p>Equipments</p> : null}
                </div>
              </div>
              <div className="row">
                {equipments.length
                  ? equipments.map((value) => (
                      <Card Image={value.image} Title={value.name} />
                    ))
                  : null}
              </div>
            </div>
            <div className="equipments">
              <div className="row">
                <div className="col-md-12">
                  {placesToStop.length ? <p>Attractions Nearby</p> : null}
                </div>
              </div>
              <div className="row">
                {placesToStop.length
                  ? placesToStop.map((value) => (
                      <Card Image={value?.image} Title={value?.name} />
                    ))
                  : null}
              </div>
            </div>
            <div className="equipments">
              <div className="row">
                <div className="col-md-12">
                  {repairPoints.length ? <p>RepairPoints</p> : null}
                </div>
              </div>
              <div className="row">
                {repairPoints?.map((value) => (
                  <Card Image={value.image} Title={value.name} />
                ))}
              </div>
            </div>
            <div className="equipments">
              <div className="row">
                <div className="col-md-12">
                  {publicTransport.length ? <p>Public Transport</p> : null}
                </div>
              </div>
              <div className="row">
                {publicTransport?.map((value) => (
                  <Card Image={value.image} Title={value.name} />
                ))}
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>Reviews</legend>
            <div className="review_container">
              {trail_data?.reviews?.length ? (
                <div className="row">
                  {trail_data?.reviews.map((value) => (
                    <div className="col-md-4">
                      <div className="reviews">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex">
                            <figure className="card_image">
                              <img src={value?.user?.profileUrl} className="" />
                            </figure>
                            <div className="card_summary">
                              <span>
                                {`${value?.user?.firstName}` +
                                  " " +
                                  `${value?.user?.lastName}`}
                              </span>
                              <StarRatings
                                starDimension="15px"
                                starSpacing="0.5px"
                                rating={value.ratings}
                                starRatedColor="#9dbf1b"
                              />
                            </div>
                          </div>
                          <div className="text-right">
                            <div class="action_group">
                              <button
                                class="btn"
                                onClick={() => review_delete_handler(value._id)}
                              >
                                <img
                                  src={IMAGES.TRASH_ICON}
                                  alt="Remove Icon"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <p>{value.review}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <label>No Reviews</label>
              )}
            </div>
          </fieldset>
          <fieldset>
            <legend>Photos</legend>
            {trail_data?.photos?.length ? (
              <div className="photos_container">
                <div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => toggleFileUploadModalView()}
                  >
                    Add
                  </button>
                </div>
                {trail_data?.photos?.map((value) => (
                  <>
                    <div className="photo_container_img col-md-3">
                      <figure>
                        <img
                          className="photos"
                          src={`https://${value.imageUrl}`}
                        />
                      </figure>
                      <div class="delete-pic">
                        <button
                          class="btn"
                          onClick={() => photos_delete_handler(value._id)}
                        >
                          <img src={IMAGES.TRASH_ICON} alt="Remove Icon" />
                        </button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            ) : (
              <>
                <div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => toggleFileUploadModalView()}
                  >
                    Add
                  </button>
                </div>
                <br />
                <label>No Photos</label>
              </>
            )}
          </fieldset>
          {/* <fieldset>
            <legend>Upload Images</legend>
            <div className="App">
            

              <div className="card-footer px-0">
                <button
                  className="btn btn-lg btn-secondary m-1"
                  type="button"
                  onClick={() => uploadImageHandler()}
                >
                  Upload Image(s)
                </button>
              </div>
            </div>
          </fieldset> */}
          <fieldset>
            <legend>Sort Images By </legend>
            <SingleSelectDropdown
              id={defaultImageSort[0]?.value}
              defaultValue={defaultImageSort[0]}
              options={imageSortOptions}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              changeHandler={(e) => {
                imageSortHandler(e);
              }}
            />
          </fieldset>

          {userActivity.length ? (
            <fieldset>
              <legend>User Activities</legend>
              <div className="d-flex active_tags">
                <Table
                  data={userActivity}
                  onClickDeleteHandler={userActivity_Delete_Handler}
                  onClickViewHandler={userActivity_View_Handler}
                />
              </div>
            </fieldset>
          ) : null}

          {/* <fieldset>
            <legend>Unapproved Images</legend>
            {trail_data?.unapprovedImages.length ? (
              loader ? (
                <Loader
                  type="Bars"
                  color="#13651B"
                  height={80}
                  width={80}
                  secondaryColor="grey"
                  visible={true}
                />
              ) : (
                <div className="photos_container">
                  {trail_data.unapprovedImages?.map((value) => (
                    <div className="images_div col-md-3">
                      <figure>
                        <img
                          className="unapproved_images"
                          src={`https://${value?.imageUrl}`}
                          />
                          <div className="action-buttons">
                            <div className="action_group">
                              <button
                                className="approve_button"
                                onClick={() => approveHandler(value?._id)}
                              >
                                Approve
                              </button>
                              <button
                                className="deny_button"
                                onClick={() => denyHandler(value?._id)}
                              >
                                Deny
                              </button>
                            </div>
                          </div>
                        
                      </figure>
                    </div>
                  ))}
                </div>
              )
            ) : (
              <label>No Photos</label>
            )}
          </fieldset> */}
          <br />
          <br />
        </>
      ) : null}
    </>
  );
}

export default withSnackbar(Trail_Info);
