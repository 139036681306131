import File_Field from "Components/atoms/FileField";
import TextField from "Components/atoms/TextField";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getOtherFacilities, postDaysOut, postOtherFacilities } from "Redux/Actions/commonCRUD";
import { LABELS, PLACEHOLDERS, REGEX, ROUTE_CONSTANTS, STRINGS } from "Shared";
import { withSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Dropdown from "Components/atoms/Dropdown";
import { updateOtherFacilities } from "Redux/Actions/Auth";


function Update_Other_Facilities(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const typeOfFacilities = [
    { value: 1, label: "Nearby Attractions" },
    { value: 3, label: "Public Transport" },
    { value: 2, label: "Garages" },
    { value: 4, label: "Bike Repair" },
  ];

  const currentFacility=useSelector((state)=>state?.facilities?.currentFacility);


  const validationSchema = Yup.object({
    [STRINGS.FACILITIES]: Yup.string()
      .min(2, "Minimum Length is 2")
      .required(STRINGS.EMPTY_FIELD)
      .max(50, "Maximum Length is 50"),
    // [STRINGS.FACILITIES_DROPDOWN]: Yup.object()
    //   .required(STRINGS.EMPTY_FIELD)
    //   .nullable(),
    [STRINGS.FACILITIES_IMAGE]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.LATITUDE]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.LONGITUDE]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.WEBSITE]: Yup.string().required(STRINGS.EMPTY_FIELD),
  });

  const defaultValue=typeOfFacilities.find((value)=>value.value==currentFacility?.facilityType)
  const submitHandler = (data) => {
      
    let formData = {
      id: currentFacility?._id,
      facilityType: currentFacility?.facilityType,
      name: data[STRINGS.FACILITIES],
      image: data[STRINGS.FACILITIES_IMAGE],
      coordinates: [data[STRINGS.LONGITUDE],data[STRINGS.LATITUDE]],
      webLink: data[STRINGS.WEBSITE]
    }

    dispatch(updateOtherFacilities(formData, (message, type) => {
      props.enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      if (type == "success") {
        if ((currentFacility?.facilityType) === 1) {
          history.push(ROUTE_CONSTANTS.NEARBY_ATTRACTIONS_HOME)
          dispatch(getOtherFacilities(1));
        }
        else if ((currentFacility?.facilityType) === 2) {
          history.push(ROUTE_CONSTANTS.GARAGES_HOME)
          dispatch(getOtherFacilities(2));
        }
        else if ((currentFacility?.facilityType) === 3) {
          history.push(ROUTE_CONSTANTS.PUBLIC_TRANSPORT_HOME)
          dispatch(getOtherFacilities(3));
        }
        else {
          history.push(ROUTE_CONSTANTS.BIKE_REPAIR_HOME)
          dispatch(getOtherFacilities(3));
        }

      }
    }))
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h4 className="h4">Edit Other Facilities</h4>
      </div>
      <div>
        <Formik
          onSubmit={submitHandler}
          initialValues={{
            [STRINGS.FACILITIES]: currentFacility?.name,
            [STRINGS.FACILITIES_DROPDOWN]: STRINGS.EMPTY_STRING,
            [STRINGS.FACILITIES_IMAGE]: currentFacility?.image,
            [STRINGS.LATITUDE]: (currentFacility?.coordinates)? currentFacility?.coordinates[1] : STRINGS.EMPTY_STRING ,
            [STRINGS.LONGITUDE]: currentFacility?.coordinates? currentFacility?.coordinates[0] : STRINGS.EMPTY_STRING,
            [STRINGS.WEBSITE]: currentFacility?.webLink ?? STRINGS.EMPTY_STRING,
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <div className="form-group">
                <label>Type of Facility</label>
                <Dropdown
                  defaultValue={defaultValue}
                  label={LABELS.COUNTRY}
                  options={typeOfFacilities}
                  className=""
                  isSearchable={true}
                  placeholder={PLACEHOLDERS.SELECT}
                  isClearable={true}
                  name={STRINGS.FACILITIES_DROPDOWN}
                  changeHandler={(e) => {
                    props.setFieldValue(STRINGS.FACILITIES_DROPDOWN, e);
                  }}
                  isDisabled={true}
                />
              </div>

              <div className="form-group">
                <TextField
                  label={LABELS.NAME}
                  placeholder={PLACEHOLDERS.WRITE_HERE}
                  name={STRINGS.FACILITIES}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label>Image</label>
                <File_Field
                  name={STRINGS.FACILITIES_IMAGE}
                  placeholder=""
                  label="Upload Image"
                  setFieldValue={props.setFieldValue}
                  id="daysout"
                  fileName="Hotel"
                  ImageUrl={currentFacility?.image}
                />
              </div>
              {/* <div className="col-md-4"> */}
              <div className="form-group">
                <TextField
                  label={STRINGS.LATITUDE}
                  placeholder={PLACEHOLDERS.WRITE_HERE}
                  name={STRINGS.LATITUDE}
                  type="number"
                />
              </div>
              {/* </div> */}
              {/* <div className="col-md-4"> */}
              <div className="form-group">
                <TextField
                  label={STRINGS.LONGITUDE}
                  placeholder={PLACEHOLDERS.WRITE_HERE}
                  name={STRINGS.LONGITUDE}
                  type="number"
                />
              </div>
              {/* </div> */}

              <div className="form-group">
                <TextField
                  label={LABELS.WEBSITE}
                  placeholder={PLACEHOLDERS.WRITE_HERE}
                  name={STRINGS.WEBSITE}
                  type="text"
                  className="without_ampm"
                />
              </div>



              <button type="submit" className="btn btn-md btn-primary">
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default withSnackbar(Update_Other_Facilities);
