import capitalize from "@mui/utils/capitalize";
import File_Field from "Components/atoms/FileField";
import CustomModal from "Components/atoms/Modal";
import TextField from "Components/atoms/TextField";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteEquipments, getEquipments, postEquipments, updateEquipments } from "Redux/Actions/commonCRUD";
import { Form_Strings, ICONS, STRINGS, titleCase } from "Shared";
import { withSnackbar } from "notistack";
import equipmentsReducer from "Redux/Reducers/equipmentsReducer";
import ServerImage from "Components/atoms/Server_Image";
import ReactPaginate from "react-paginate";
import "./style.scss";
import { updateLocale } from "moment";

function Equipments(props) {
  const [error, setError] = useState(false);
  const [showEditModal, setModalView] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);
  const [editModalOpen,setEditModalOpen]=useState(false);
  const equipments = useSelector((state) => state.equipments.equipments);
  const dispatch = useDispatch();
  const [items, setItems] = useState(equipments);
  const [currentItems, setCurrentItems] = useState(items);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [data,setData]=useState(0);
  const itemsPerPage = 5;
  
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  useEffect(() => {
    setItems(equipments);
  }, [equipments]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    dispatch(getEquipments());
  };

  const addHandler = () => {
    setModalView(false);
    toggleModalView();
  };

  const deleteHandler=(data)=>{
    dispatch(deleteEquipments(data,(message, type) => {
      props.enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      if (type == "success") {
        dispatch(getEquipments())
      }
    }))
  }


  const toggleModalView = () => {
    setmodalOpen((modalOpen) => !modalOpen);
  };

  const toggleEditModalView=()=>{
  setEditModalOpen((editModalOpen) => !editModalOpen);
  }
  const imageHandler=()=>{
    setData(null);
  }
  const submitHandler = (data) => {
    let values = {
      name: data.Equipments,
      image: data[STRINGS.FACILITIES_IMAGE],
    };

   


    dispatch(
      postEquipments(values, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          setModalView(true);
          toggleModalView();
          dispatch(getEquipments())
        }
      })
    );
  };

  const editSubmitHandler=(value)=>{
    let formData={
      id: data?.key,
      name:value?.Name,
      image:value?.Equipment
    }
    
    dispatch(updateEquipments(formData,(message, type) => {
      props.enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      if (type == "success") {
        setModalView(true);
        toggleEditModalView();
        dispatch(getEquipments())
      }
    }))
  }

  const editHandler=(val)=>{
    // console.log(val)
    toggleEditModalView();
    setData(val);
  }
  useEffect(() => {
    dispatch(getEquipments());
  }, []);

  return (
    <>
      <CustomModal isOpen={modalOpen} handleToggle={toggleModalView}>
        <Formik onSubmit={submitHandler} initialValues={{
        }}>
          {(props) => (
            <Form>
              <div className="modal-header justify-content-center">
                <h3 className="h4">Add New Equipment</h3>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    {/* <label>Name</label> */}
                    <div className="form-group">
                      <TextField
                        label={STRINGS.NAME}
                        name="Equipments"
                        type="text"
                        className="form-control"
                      />
                      {error ? (
                        <span className="error">This is a required field</span>
                      ) : null}
                    </div>
                    <label>Image</label>
                    <File_Field
                      name={STRINGS.EQUIPMENT}
                      setFieldValue={props.setFieldValue}
                      id="trail"
                      showmap={false}
                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-md btn-cancel" onClick={()=>toggleModalView()}>
                  Cancel
                </button>
                <button className="btn btn-md btn-primary" type="submit">
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModal>

      <CustomModal isOpen={editModalOpen} handleToggle={toggleEditModalView}>
        <Formik onSubmit={editSubmitHandler} initialValues={{
          [STRINGS.NAME]: data?.text,
          [STRINGS.EQUIPMENT]: data?.imagePath
        }}>
          {(props) => (
            <Form>
              <div className="modal-header justify-content-center">
                <h3 className="h4">Edit Equipment</h3>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    {/* <label>Name</label> */}
                    <div className="form-group">
                      <TextField
                        label="Equipments"
                        name={STRINGS.NAME}
                        type="text"
                        className="form-control"
                      />
                      {error ? (
                        <span className="error">This is a required field</span>
                      ) : null}
                    </div>
                    <label>Image</label>
                    <File_Field
                      name={STRINGS.EQUIPMENT}
                      setFieldValue={ STRINGS.EQUIPMENT || props.setFieldValue}
                      id="trail"
                      showmap={false}
                      showImage={false}
                      ImageUrl={data?.imagePath}
                      clickHandler={imageHandler}
                      showmapIcon={false}
                      fileName="Trail"
                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-md btn-cancel" onClick={()=>toggleEditModalView()}>
                  Cancel
                </button>
                <button className="btn btn-md btn-primary" type="submit">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModal>
      
      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h2 className="h4">Equipment</h2>

        <div className="col_rht">
          <button
            onClick={() => addHandler()}
            className="btn btn-sm btn-primary"
          >
            Add Equipment
          </button>
        </div>
      </div>

      <div class="table-responsive custom_table">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>

          {currentItems?.map((value) => (
            <tbody index={value?._id}>
              <tr>
                <td>
                  <ServerImage
                    url={value?.imagePath}
                    width="50px"
                    height="50px"
                    className="server_image"
                  />
                </td>
                <td>{capitalize(value?.text)}</td>
                <td>
                  <div class="action_group">
                    <button
                      class="btn"
                      onClick={() => editHandler(value)}
                    >
                      <img src={ICONS.EditIcon} alt="Edit Icon" />
                    </button>
                    <button
                      class="btn"
                        onClick={() => deleteHandler(value?.key)}
                    >
                      <img src={ICONS.RemoveIcon} alt="Remove Icon" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}

export default withSnackbar(Equipments);
