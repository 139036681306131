import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Capitalize, ROUTE_CONSTANTS, STRINGS, titleCase } from "Shared";
import { withSnackbar } from "notistack";
import { deleteOtherFacilities, getOtherFacilities } from "Redux/Actions/commonCRUD";

function Attractions_Nearby_Info(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentNearbyAttraction = useSelector(
    (state) => state.facilities.currentNearbyAttraction
  );

  const deleteHandler=(id)=>{
    dispatch(
      deleteOtherFacilities(id, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });

        if (type == "success") {
          history.push(ROUTE_CONSTANTS.NEARBY_ATTRACTIONS_HOME);
          dispatch(getOtherFacilities(1));
        }
      })
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h4 className="h4">Attractions Nearby</h4>
        <div className="col_rht">
          <button className="btn btn-sm btn-primary" 
          onClick={()=>{
            history.push(ROUTE_CONSTANTS.EDIT_OTHER_FACILTIES)
          }}>Edit</button>
          <button
            className="btn btn-sm btn-cancel"
            onClick={() => deleteHandler(currentNearbyAttraction?._id)}
          >
            Delete
          </button>
        </div>
      </div>
      <div>
        <>
        <div className="basic_info">
          <div className="photos_list">
            <div className="photo_items">
              <figure>
                <img src={`https://${currentNearbyAttraction?.imageUrl}`} />
              </figure>
            </div>
          </div>
            <div className="row">
              <div className="col-md-4">
                <div className="info_block">
                  <label>{STRINGS.NAME}</label>
                  <span>{Capitalize(currentNearbyAttraction?.name)}</span>
                </div>
                <div className="info_block">
                  <label>{STRINGS.WEBSITE}</label>
                  <span>{currentNearbyAttraction?.webLink ?? "---"} </span>
                </div>
            </div>
          </div>
          </div>
        </>
      </div>
    </>
  );
}

export default withSnackbar(Attractions_Nearby_Info);
