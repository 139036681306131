import Nav_Bar from "Components/hoc/NavBar";
import Stay_Eat_Screen from "./form";

function Stay_Eat(){
    return(
        <>
        
        <Stay_Eat_Screen/>
        </>
    )

}

export default Stay_Eat;