
import Reset_Password_Form from "./form";

function Reset_Password_Screen(){
    return(
        <>
        <Reset_Password_Form/>
        
        </>
    )
}

export default Reset_Password_Screen;