import React from "react";
import { useField, ErrorMessage, useFormikContext } from "formik";
import { STRINGS, LABELS, PLACEHOLDERS } from "./../../../Shared/Constants"

function TextArea(props) {
    const [field, meta] = useField(props);
    

    return (
        <>
            <label htmlFor={field.name}> {props.label} </label>
            <textarea {...field}{...props} autoComplete="off" rows={3} className="form-control" />           
            <div className="error"><ErrorMessage name={field.name} /></div> 
        </>

    )
}

export default TextArea;