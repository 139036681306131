import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStayEatDetails, getStayHome } from "Redux/Actions/commonCRUD";
import { ICONS, ROUTE_CONSTANTS, titleCase } from "Shared";
import { useHistory } from "react-router";
import "./style.scss";
import ReactPaginate from "react-paginate";
import ServerImage from "Components/atoms/Server_Image";

function Stay_Home() {

    const dispatch = useDispatch();
    const all_StayEat = useSelector((state) => state.stayEat.stayOrEat);
    const [stayEat, setStayEat] = useState(all_StayEat);
    const history = useHistory();
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [items, setItems] = useState(all_StayEat);
    const [currentItems, setCurrentItems] = useState(items);
    const itemsPerPage = 8;
  
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const clickHandler=(id)=>{
    dispatch(getStayEatDetails(id));
    history.push(ROUTE_CONSTANTS.STAY_EAT_INFO.slice(0, -3) + id);
  }

  

  useEffect(() => {
    setStayEat(all_StayEat);
    setItems(all_StayEat);
    setCurrentItems(items);
  }, [all_StayEat]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  useEffect(()=>{
      dispatch(getStayHome());
  },[])

  // console.log(currentItems)

  return (
    <>
      <>
        <div className="d-flex justify-content-between flex-wrap w-100 page-title">
          <h4 className="h4">Stay or Eat</h4>

          <div className="col_rht">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => history.push(ROUTE_CONSTANTS.ADMIN_ADD_STAY_OR_EAT)}
            >
              Add Stay or Eat
            </button>
          </div>
        </div>

        <div className="row trail_list">
          {currentItems?.map((value, index) => (
            <div
              className="col-xl-3 col-md-4 col-sm-6"
              onClick={() => clickHandler(value._id)}
            >
              <div className="trail_items">
                <figure>
                  <img
                    src ={`https://${value.imageUrl}`}
                    width="230px"
                    height="200px"
                    className="img-fluid"
                  />
                </figure>
                <div className="trail_summary">
                  <h6>{titleCase(value.name)}</h6>
                  {/* <span className="trail_address">
                      <img src={ICONS.PinIcon} alt="Location" width="12" />
                    <i className="mr-2">
                    </i>
                    {value?.address}
                  </span> */}
                </div>
              
              </div>
            </div>
          ))}
        </div>
        

        <ReactPaginate
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    </>
  );
}

export default Stay_Home;
