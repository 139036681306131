import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "Redux/Actions/filterCRUD";
import moment from "moment";
import { ROUTE_CONSTANTS, titleCase } from "Shared";
import CustomModal from "Components/atoms/Modal";
import TextField from "Components/atoms/TextField";
import { ICONS } from "Shared/Images";
import { withSnackbar } from "notistack";
import capitalize from "@mui/utils/capitalize";
import { useHistory, useLocation } from "react-router";
import ReactPaginate from "react-paginate";
import SingleSelectDropdown from "Components/atoms/SingleSelectDropdown";
import { deleteOtherFacilities, getOtherFacilities, postOtherFacilities, setEmergencyNumbers } from "Redux/Actions/commonCRUD";
import { updateOtherFacilities } from "Redux/Actions/Auth";

function Emergency_Numbers(props) {


    const [name, setName] = useState("");
    const [emergencyNumber,setemergencyNumber]=useState("")
    const emergencyNumbers = useSelector((state) => state?.facilities?.emergencyNumbers);
    const [index, setIndex] = useState();
    const [modalOpen, setmodalOpen] = useState(false);
    const [error, setError] = useState(false);
    const [errorNum, setErrorNum] = useState(false);
    const [showEditModal, setModalView] = useState(false);
    const [edit_emergencyNumber, set_edit_emergencyNumber] = useState("");
    const [update_emergencyNumber_Id, set_update_emergencyNumber_Id] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentItems, setCurrentItems] = useState(items);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [items, setItems] = useState(emergencyNumbers);
    const itemsPerPage = 10;
    const [editEmergencyNumber, setEditEmergencyNumber] = useState();

    const toggleModalView = () => {
        setmodalOpen((modalOpen) => !modalOpen);
        setError(false)
        setErrorNum(false)
    };

    const submitHandler = () => {

        let formData = {
            facilityType: 5,
            name: name,
            contactNumber: emergencyNumber.toString()
        };
        if (!emergencyNumber.length && !name.length) {
            setError(true);
            setErrorNum(true)
        }  else if (!name.length) {
            setError(true);

        }else if (!emergencyNumber.length) {
            setErrorNum(true);
        }
        else {
            dispatch(postOtherFacilities(formData, (message, type) => {
                props.enqueueSnackbar(message, {
                    variant: type,
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
                if (type == "success") {
                    dispatch(getOtherFacilities(5));
                    toggleModalView();
                    setName("");
                    setemergencyNumber("");
                }
            }))
        }
    };


    const updateHandler = () => {

        if (!edit_emergencyNumber?.length && !emergencyNumber.length) {
            setError(true);
            setErrorNum(true)
        }
        else if (!edit_emergencyNumber?.length) {
            setError(true);
        }
        else if (!emergencyNumber?.length) {
            setErrorNum(true)
        }

        else  {
            let data = {
                id: update_emergencyNumber_Id,
                facilityType:5,
                name: edit_emergencyNumber,
                contactNumber: emergencyNumber.toString()
            };
       
            dispatch(updateOtherFacilities(data, (message, type) => {
                    props.enqueueSnackbar(message, {
                        variant: type,
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                    });
                    if (type == "success") {
                        dispatch(getOtherFacilities(5));
                        setItems(emergencyNumbers);
                        setemergencyNumber("");
                        setError(false);
                        toggleModalView();
                    }
                })
            );
        }
    };

    const addHandler = () => {
        setModalView(false);
        toggleModalView();
    };

    const editHandler = (data) => {
        let current_emergencyNumber = emergencyNumbers.filter(
            (value) => value._id === data._id
        );
        setModalView(true);
        toggleModalView();
        setIndex(index);
        set_update_emergencyNumber_Id(data?._id);
        set_edit_emergencyNumber(current_emergencyNumber[0]?.name);
        setemergencyNumber(data?.contactNumber);
    };

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
        dispatch(getCategories());
    };

    const deleteHandler = (data) => {
        // let temp_categories = [...items];
        dispatch(
          deleteOtherFacilities(data, (message, type) => {
            props.enqueueSnackbar(message, {
              variant: type,
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            });
            if (type == "success") {
                dispatch(getOtherFacilities(5))
            }
          })
        );
    };

    useEffect(() => {
        dispatch(getOtherFacilities(5));
    }, []);

    useEffect(() => {
        setItems(emergencyNumbers);
        setCurrentItems(emergencyNumbers);
    }, [emergencyNumbers]);
    
    return (
        <>
            <CustomModal isOpen={modalOpen} handleToggle={toggleModalView}>
                {!showEditModal ? (
                    <>
                        <div className="modal-header justify-content-center">
                            <h3 className="h4">Add Number</h3>
                        </div>

                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <label>Name</label>
                                    <div className="form-group">
                                        <input
                                            placeholder="Write Here ..."
                                            name="emergencyNumber"
                                            type="text"
                                            value={titleCase(name)}
                                            className="form-control"
                                            onChange={(e) => {
                                                setError(false)
                                                setName(e.target.value)}}
                                        />
                                        {error ? (
                                            <span className="error">This is a required field</span>
                                        ) : null}


                                    </div>
                                    <label>Number</label>
                                    <div className="form-group">
                                        <input
                                            placeholder="Write Here ..."
                                            name="emergencyNumber"
                                            type="number"
                                            value={emergencyNumber}
                                            className="form-control"
                                            onChange={(e) => {
                                                setErrorNum(false)
                                                setemergencyNumber(e.target.value)}}
                                        />
                                        {errorNum ? (
                                            <span className="error">This is a required field</span>
                                        ) : null}


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer justify-content-center">
                            <button
                                className="btn btn-md btn-cancel"
                                onClick={() => toggleModalView()}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-md btn-primary"
                                onClick={() => {
                                    submitHandler();
                                }}
                            >
                                Add
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="modal-header justify-content-center">
                            <h3>Edit Number</h3>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <label></label>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input
                                            className="form-control"
                                            placeholder="Write Here ..."
                                            name="emergencyNumber"
                                            type="text"
                                            value={titleCase(edit_emergencyNumber)}
                                            onChange={(e) => {
                                                setError(false)
                                                set_edit_emergencyNumber(e.target.value)}}
                                        />
                                        {error ? (
                                            <span className="error" a>
                                                This is a Required Field
                                            </span>
                                        ) : null}
                                    </div>
                                    <label>Number</label>
                                    <div className="form-group">
                                        <input
                                            placeholder="Write Here ..."
                                            name="emergencyNumber"
                                            type="number"
                                            value={emergencyNumber}
                                            className="form-control"
                                            onChange={(e) => {
                                                setErrorNum(false)
                                                setemergencyNumber(e.target.value)}}
                                        />
                                        {errorNum ? (
                                            <span className="error">This is a required field</span>
                                        ) : null}


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer justify-content-center">
                            <button
                                className="btn btn-md btn-cancel"
                                onClick={() => {
                                    toggleModalView()
                                    setError(false)
                                }
                                }
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-md btn-primary"
                                onClick={() => {
                                    updateHandler();
                                }}
                            >
                                Update
                            </button>
                        </div>
                    </>
                )}
            </CustomModal>

            <div className="d-flex justify-content-between flex-wrap w-100 page-title">
                <h2 className="h4">Emergency Number</h2>
                {/* <div className="col-md-4">
          <div className="form-group">
            <label>Sort by  </label>
            <SingleSelectDropdown
              id={defaultSortValue[0]?.value}
              options={sortingOptions}
              defaultValue={defaultSortValue[0]}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              changeHandler={(e) => {
                sortHandler(e)
              }}
            />
            <label>This sorting would reflect on App side</label>
          </div>
        </div> */}

                <div className="col_rht d-flex align-items-center">
                    <button
                        onClick={() => addHandler()}
                        className="btn btn-sm btn-primary"
                    >
                        Add Number
                    </button>
                </div>
            </div>

            <div class="table-responsive custom_table">
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Number</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    {currentItems?.length && currentItems?.map((value) => (
                        <tbody index={value._id}>
                            <tr>
                                <td>{capitalize(value.name)}</td>
                                <td>
                                    <span className="time">
                                        {value?.contactNumber}
                                    </span>
                                </td>

                                <td>
                                    <div class="action_group">
                                        <button class="btn" onClick={() => editHandler(value)}>
                                            <img src={ICONS.EditIcon} alt="Edit Icon" />
                                        </button>
                                        <button
                                            class="btn"
                                            onClick={() => deleteHandler(value._id)}
                                        >
                                            <img src={ICONS.RemoveIcon} alt="Remove Icon" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>

            <ReactPaginate
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </>
    )

}

export default withSnackbar(Emergency_Numbers);