
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getOtherFacilities, setCurrentBikeRepair, setCurrentFacility } from "Redux/Actions/commonCRUD";
import { ROUTE_CONSTANTS, titleCase } from "Shared";

function Bike_Repair_Home() {
  const bikeRepairData = useSelector((state) => state.facilities.bikeRepair);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState(bikeRepairData);
  const [currentItems, setCurrentItems] = useState(items);
  const itemsPerPage = 8;
  const history = useHistory();
  const dispatch = useDispatch();
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };
  const clickHandler=(data)=>{
    dispatch(setCurrentBikeRepair(data));
    dispatch(setCurrentFacility(data));
    history.push(ROUTE_CONSTANTS.BIKE_REPAIR_INFO.slice(0, -3) + data?._id)

  }
  useEffect(() => {
    setItems(bikeRepairData);
    setCurrentItems(items);
  }, [bikeRepairData]);

  useEffect(() => {
    dispatch(getOtherFacilities(4));
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  return (
    <>
      <>
        <div className="d-flex justify-content-between flex-wrap w-100 page-title">
          <h4 className="h4">Bike Repair Point</h4>

          <div className="col_rht">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => history.push(ROUTE_CONSTANTS.ADD_OTHER_FACILTITES)}
            >
              Add Bike Repair Point
            </button>
          </div>
        </div>

        <div className="row trail_list">
          {currentItems?.map((value, index) => (
            <div
              className="col-xl-3 col-md-4 col-sm-6"
              onClick={() => clickHandler(value)}
            >
              <div className="trail_items">
                <figure>
                  <img
                    src={`https://${value?.imageUrl}`}
                    width="230px"
                    height="200px"
                    className="img-fluid"
                  />
                </figure>
                <div className="trail_summary">
                  <h6>{titleCase(value.name)}</h6>
                  <span className="trail_address">{value.address}</span>
                </div>
                {/* <div className="buttons">
                <button className="delete_button">D</button>
                <button className="edit_button">E</button>
              </div> */}
              </div>
            </div>
          ))}
        </div>

        <ReactPaginate
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    </>
  );
}

export default Bike_Repair_Home;
