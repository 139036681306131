import Tag from "Components/atoms/Tag";
import TextField from "Components/atoms/TextField";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import File_Field from "Components/atoms/FileField";
import CustomModal from "Components/atoms/Modal";
// import TextField from "Components/atoms/TextField";
import { Form, Formik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import { ACTION_STATES } from "Redux/Actions/ActionStates";
import { POST_FILTERS } from "Redux/Actions/ActionType";
import { getFilter, postFilter } from "Redux/Actions/filterCRUD";
import { LABELS, PLACEHOLDERS, STRINGS } from "Shared";
import "./style.css";
import { withSnackbar } from "notistack";
import SingleSelectDropdown from "Components/atoms/SingleSelectDropdown";
import { getFilterByLanguage, set_SuitabilityImage } from "Redux/Actions/commonCRUD";
import ServerImage from "Components/atoms/Server_Image";

function Add_Filters(props) {
  const [difficulty_level, set_difficulty_level] = useState([]);
  const [suitability, set_suitability] = useState([]);
  const [trail_type, set_trail_type] = useState([]);
  const filters = useSelector((state) => state.filter.filters);
  const dispatch = useDispatch();
  const [language, setLanguage] = useState({ value: "en", label: "English" });
  const [difficulty_level_value, set_difficulty_level_value] = useState();
  const [activity_value, set_activity_value] = useState();
  const [trail_type_value, set_trail_type_value] = useState();
  const [viewDifficultyLevel, setViewDifficultyLevel] = useState([]);
  const [viewSuitability, setViewSuitability] = useState([]);
  const [viewTrailType, setViewTrailType] = useState([]);
  const [errorImg, setErrorImg] = useState(false)
  const [errorValue, setErrorValue] = useState(false)
  const [suitability_value, set_suitability_value] = useState("");
  const [suitability_image, set_suitability_image] = useState();

  const [modalOpen, setmodalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState({});
  // let suitabilityImage;
  // suitabilityImage = useSelector((state) => state?.suitability?.suitabiltyImage);
  // const [suitbilityImg, setSuitbilityImg] = useState("");

  const tag_selected_style = {
    background: "#9dbf1b",
    border: "1px solid #1F4B81",
  };
  const tag_unselected_style = {
    background: "#EBEBEB",
    border: "1px solid #E9550C",
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (filters && filters?.length) {
      set_difficulty_level(
        filters
          .filter((value) => value.type === 4)
          ?.map((value) => value.filter)[0]
          ?.map((data) =>
            Object.assign(
              {},
              {
                name: data.name,
                isSelected: data.isSelected,
                isDeleted: data.isDeleted,
                type: 4,
              }
            )
          )
      );
      setViewDifficultyLevel(
        filters
          .filter((value) => value.type === 4)
          ?.map((value) => value.filter)[0]
          ?.map((data) =>
            Object.assign(
              {},
              {
                name: data.name,
                isSelected: data.isSelected,
                isDeleted: data.isDeleted,
                type: 4,
              }
            )
          )
      );
    }

    if (filters && filters?.length) {
      set_trail_type(
        filters
          .filter((value) => value.type === 3)
          ?.map((value) => value.filter)[0]
          ?.map((data) =>
            Object.assign(
              {},
              {
                name: data.name,
                isSelected: data.isSelected,
                isDeleted: data.isDeleted,
                type: 3,
              }
            )
          )
      );
      setViewTrailType(
        filters
          .filter((value) => value.type === 3)
          ?.map((value) => value.filter)[0]
          ?.map((data) =>
            Object.assign(
              {},
              {
                name: data.name,
                isSelected: data.isSelected,
                isDeleted: data.isDeleted,
                type: 3,
              }
            )
          )
      );
    }

    if (filters && filters?.length) {
      set_suitability(
        filters
          .filter((value) => value.type === 1)
          ?.map((value) => value.filter)[0]
          ?.map((data) =>
            Object.assign(
              {},
              {
                name: data.name,
                isSelected: data.isSelected,
                isDeleted: data.isDeleted,
                type: 1,
                imageUrl: data?.imageUrl,
              }
            )
          )
      );
      setViewSuitability(
        filters
          .filter((value) => value.type === 1)
          ?.map((value) => value.filter)[0]
          ?.map((data) =>
            Object.assign(
              {},
              {
                name: data.name,
                isSelected: data.isSelected,
                isDeleted: data.isDeleted,
                type: 1,
                imageUrl: data?.imageUrl,
              }
            )
          )
      );
    }
  }, [filters]);

  const difficulty_tag_handler = (tagIndex) => {
    if (difficulty_level?.length) {
      set_difficulty_level((value) => {
        return value.map((data, index) =>
          tagIndex === index ? Object.assign({}, data, { isSelected: !data.isSelected }) : data
        );
      });
      setViewDifficultyLevel((value) => {
        return value.map((data, index) =>
          tagIndex === index ? Object.assign({}, data, { isSelected: !data.isSelected }) : data
        );
      });
    }
  };

  const suitability_tag_handler = (tagIndex) => {
    if (suitability.length) {
      set_suitability((value) => {
        return value.map((data, index) =>
          tagIndex === index ? Object.assign({}, data, { isSelected: !data.isSelected }) : data
        );
      });

      setViewSuitability((value) => {
        return value.map((data, index) =>
          tagIndex === index ? Object.assign({}, data, { isSelected: !data.isSelected }) : data
        );
      });
    }
  };

  const add_difficulty_handler = () => {
    let data = {
      name: difficulty_level_value,
      isSelected: true,
      isDeleted: false,
      type: 4,
    };

    if (data?.name?.length) {
      difficulty_level.push(data);
      viewDifficultyLevel.push(data);
    }
    set_difficulty_level_value("");
  };

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "fr", label: "French" },
    { value: "es", label: "Spanish" },
    { value: "de", label: "German" },
  ];
  const add_trail_type_handler = () => {
    let data = {
      name: trail_type_value,
      isSelected: true,
      isDeleted: false,
      type: 3,
    };
    if (data?.name?.length) {
      trail_type.push(data);
      viewTrailType.push(data);
    }
    set_trail_type_value("");
  };
  const add_suitability_handler = () => {
    // console.log(suitability_image, "suitability_image <>><>><?")
    if (!suitability_image) {
      setErrorImg(true)
      // console.log("suitability_imagedfsdf")
    } else {
      let data = {
        name: suitability_value,
        isSelected: true,
        isDeleted: false,
        type: 1,
        imageUrl: suitability_image,
      };
      
      // console.log("1212suitability_imagedfsdf", data)
    if (data?.name?.length) {
      suitability.push(data);
      viewSuitability.push(data);
      set_suitability_value("");
      toggleModalView();
    }}
  };
  const edit_suitability_handler = () => {
    let data = {
      name: editModalData.name,
      isSelected: editModalData.isSelected,
      isDeleted: editModalData.isDeleted,
      type: 1,
      imageUrl: suitability_image,
    };
    if (data?.name?.length) {
      suitability[editModalData?.index] = data;
      viewSuitability.push(data);
      set_suitability_value("");
      set_suitability_image("");
      toggleEditModalView();
    }
  };

  const difficulty_delete_handler = (tagIndex) => {
    viewDifficultyLevel[tagIndex].isDeleted = true;
    difficulty_level.splice(tagIndex, 1);
  };

  const suitability_delete_handler = (tagIndex) => {
    viewSuitability[tagIndex].isDeleted = true;
    suitability.splice(tagIndex, 1);
  };

  const trail_type_delete_handler = (tagIndex) => {
    viewTrailType[tagIndex].isDeleted = true;
    trail_type.splice(tagIndex, 1);
  };

  const submitHandler = () => {
    let data = {
      filters: [...viewDifficultyLevel, ...viewSuitability, ...viewTrailType],
    };

    dispatch(
      postFilter(data, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        dispatch(getFilter());
      })
    );
  };

  const trail_type_tag_handler = (tagIndex) => {
    if (trail_type.length) {
      set_trail_type((value) => {
        return value.map((data, index) =>
          tagIndex === index ? Object.assign({}, data, { isSelected: !data.isSelected }) : data
        );
      });
      setViewTrailType((value) => {
        return value.map((data, index) =>
          tagIndex === index ? Object.assign({}, data, { isSelected: !data.isSelected }) : data
        );
      });
    }
  };

  useEffect(() => {
    if (language.value) {
      dispatch(getFilterByLanguage(language.value));
    }
  }, [language]);

  useEffect(() => {
    dispatch(getFilter());
  }, []);

  // useEffect(() => {
  //   setSuitbilityImg(suitabilityImage);
  // }, [suitabilityImage]);
  const toggleModalView = () => {
    setmodalOpen((modalOpen) => !modalOpen);
    set_suitability_image("");
    setErrorImg(false)
  };
  const toggleEditModalView = (data, index) => {
    editModalOpen ? set_suitability_image("") : set_suitability_image(data.imageUrl);
    setEditModalOpen((editModalOpen) => !editModalOpen);
    setEditModalData({ ...data, index: index });
  };

  // const validationSchema = Yup.object({
  //   [STRINGS.EQUIPMENT]:Yup.string().required("This is a required field")
  // });

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h4 className="h4">Filters</h4>
        {/* <div className="col-md-4">
          <div className="form-group">
            <label>Language</label>
            <SingleSelectDropdown
              //  id={defaultSortValue[0]?.value}
              options={languageOptions}
              defaultValue={languageOptions[0]}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              changeHandler={(e) => {
                setLanguage(e);
              }}
            />
            <label>This sorting would reflect on App side</label>
          </div>
        </div> */}
      </div>

      <div className="form_sec">
        <fieldset>
          <legend>Difficulty Level</legend>
          <div className="form-row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  label="Name"
                  placeholder={PLACEHOLDERS.WRITE_HERE}
                  value={difficulty_level_value}
                  onChange={(e) => {set_difficulty_level_value(e.target.value)}}
                  className="form-control"
                />
                
              </div>
            </div>

            <div className="col-md-4">
              <label className="d-none d-md-block">&nbsp;</label>
              <button className="btn btn-md btn-primary" onClick={() => add_difficulty_handler()}>
                Add
              </button>
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                {/* <label>Activity Difficulty</label> */}
                <div className="d-flex active_tags">
                  {difficulty_level.map((value, tagIndex) => (
                    <Tag
                      {...value}
                      tag_selected_style={tag_selected_style}
                      tag_unselected_style={tag_unselected_style}
                      tagIndex={tagIndex}
                      tag_clickHandler={difficulty_tag_handler}
                      is_deletable={true}
                      delete_handler={difficulty_delete_handler}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        {/* <fieldset>
          <legend>Activity</legend>
          <div className="form-row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  label="Name"
                  placeholder={PLACEHOLDERS.WRITE_HERE}
                  value={activity_value}
                  onChange={(e) => set_activity_value(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="d-none d-md-block">&nbsp;</label>
                <button
                  className="btn btn-md btn-primary"
                  onClick={() => add_activity_handler()}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Activated</label>
                <div className="d-flex active_tags">
                  {activity.map((value, tagIndex) =>
                    !value.isDeleted ? (
                      <Tag
                        {...value}
                        tag_selected_style={tag_selected_style}
                        tag_unselected_style={tag_unselected_style}
                        tagIndex={tagIndex}
                        tag_clickHandler={tag_clickHandler}
                        is_deletable={true}
                        delete_handler={activity_delete_handler}
                      />
                    ) : null
                  )}
                </div>
              </div>
            </div>
          </div>
        </fieldset> */}

        <CustomModal isOpen={modalOpen} handleToggle={toggleModalView}>
          <Formik onSubmit={add_suitability_handler} initialValues={{
            // [STRINGS.EQUIPMENT]: STRINGS.EMPTY_STRING
          }} >
            {(props) => (
              <Form>
                <div className="modal-header justify-content-center">
                  <h3 className="h4">Add Image</h3>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      {/* <label>Name</label> */}
                      {/* <div className="form-group">
                                            <TextField
                                                label={STRINGS.NAME}
                                                name="Equipments"
                                                type="text"
                                                className="form-control"
                                            />
                                            {error ? (
                                                <span className="error">This is a required field</span>
                                            ) : null}
                                        </div> */}
                      <div className="form-group">
                        <label>Image</label>
                        <File_Field
                          name={STRINGS.EQUIPMENT}
                          setFieldValue={(img) => {
                            set_suitability_image(img)
                            setErrorImg(false)
                          }}
                          id="trail"
                          showmap={false}
                          fileName="filter"
                        />
                        {errorImg ? ( <span className="error">This is a required field</span> ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer justify-content-center">
                  <button type="button" className="btn btn-md btn-cancel" onClick={() => toggleModalView()}>
                    Cancel
                  </button>
                  <button className={`btn btn-md btn-success`}  type="submit">
                    Add
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </CustomModal>

        <CustomModal isOpen={editModalOpen} handleToggle={toggleEditModalView}>
          <Formik
            onSubmit={edit_suitability_handler}
            initialValues={
              {
                // [STRINGS.NAME]: editModalData?.name,
                // [STRINGS.EQUIPMENT]: editModalData?.image,
              }
            }
          >
            {(props) => (
              <Form>
                <div className="modal-header justify-content-center">
                  <h3 className="h4">Edit Image</h3>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      {/* <label>Name</label> */}
                      {/* <div className="form-group">
                                            <TextField
                                                label="Name"
                                                name={STRINGS.NAME}
                                                // setFieldValue={editModalData?.name}
                                                type="text"
                                                className="form-control"
                                            />
                                            {error ? (
                                                <span className="error">This is a required field</span>
                                            ) : null}
                                        </div> */}
                      <label>Image</label>
                      <File_Field
                        name={STRINGS.EQUIPMENT}
                        setFieldValue={(data) => set_suitability_image(data)}
                        id="trail"
                        showmap={false}
                        showImage={false}
                        ImageUrl={editModalData?.imageUrl}
                        // clickHandler={imageHandler}
                        showmapIcon={false}
                        fileName="filter"
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer justify-content-center">
                  <button type="button" className="btn btn-md btn-cancel" onClick={() => toggleEditModalView()}>
                    Cancel
                  </button>
                  <button
                    className={`btn btn-md btn-primary`}
                    type="submit"
                    // onClick={() => (suitability_image ? edit_suitability_handler() : null)}
                    disabled={!suitability_image}
                  >
                    Update
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </CustomModal>

        <fieldset>
          <legend>Suitability</legend>
          <div className="form-row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  label="Name"
                  placeholder={PLACEHOLDERS.WRITE_HERE}
                  value={suitability_value}
                  onChange={(e) => {
                    setErrorValue(false)
                    set_suitability_value(e.target.value)}}
                  className="form-control"
                />
                {errorValue ? ( <span className="error">This is a required field</span> ) : null}
              </div>
            </div>

            <div className="col-md-4">
              <label className="d-none d-md-block">&nbsp;</label>
              <button
                className="btn btn-md btn-success"
                onClick={() => {
                  if (!suitability_value.length) {
                    setErrorValue(true)
                  } else {
                    toggleModalView();
                  }
                }}
              >
                Add
              </button>
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                {/* <label>Activated</label> */}
                <div className="d-flex active_tags">
                  {suitability.map((value, tagIndex) => (
                    <Tag
                      {...value}
                      tag_selected_style={tag_selected_style}
                      tag_unselected_style={tag_unselected_style}
                      tagIndex={tagIndex}
                      tag_clickHandler={suitability_tag_handler}
                      is_deletable={true}
                      delete_handler={suitability_delete_handler}
                      toggleEditModalView={toggleEditModalView}
                      suitability="suitability"
                      data={value}
                      // setSuitbilityImg={setSuitbilityImg}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Trail Type</legend>
          <div className="form-row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  label="Name"
                  placeholder={PLACEHOLDERS.WRITE_HERE}
                  value={trail_type_value}
                  onChange={(e) => set_trail_type_value(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className="d-none d-md-block">&nbsp;</label>
                <button className="btn btn-md btn-primary" onClick={() => add_trail_type_handler()}>
                  Add
                </button>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-12">
              <div className="form-group">
                {/* <label>Activated</label> */}
                <div className="d-flex active_tags">
                  {trail_type.map((value, tagIndex) => (
                    <Tag
                      {...value}
                      tag_selected_style={tag_selected_style}
                      tag_unselected_style={tag_unselected_style}
                      tagIndex={tagIndex}
                      tag_clickHandler={trail_type_tag_handler}
                      is_deletable={true}
                      delete_handler={trail_type_delete_handler}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <div className="card-footer px-0">
          <button className="btn btn-lg btn-secondary mt-2" onClick={() => submitHandler()}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default withSnackbar(Add_Filters);
