import Dropdown from "Components/atoms/Dropdown";
import File_Field from "Components/atoms/FileField";
import TextField from "Components/atoms/TextField";
import { Formik, Form } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addStayEat, getStayHome, gettrails } from "Redux/Actions/commonCRUD";
import { Form_Strings, LABELS, PLACEHOLDERS, REGEX, ROUTE_CONSTANTS, STRINGS } from "Shared";
import * as Yup from "yup";
import { withSnackbar } from "notistack";
import { useHistory } from "react-router";
import Multi_Select_Dropdown from "Components/atoms/Multi_Select_Dropdown";



function Stay_Eat_Screen(props) {

  const dispatch = useDispatch();
  let trails = useSelector((state) => state.trails.trails) || [];
  let allTrails = trails.map((value) => Object.assign({}, { label: value.trailName, value: value._id }))
  const history = useHistory();


  const submitHandler = (data) => {
    let formData = {
      name: data[Form_Strings.NAME],
      // description: data[Form_Strings.DESCRIPTION],
      trail: data[Form_Strings.TRAIL].map((value) => value.value),
      imageUrl: data[STRINGS.STAY_EAT_IMAGE],
      address: data[Form_Strings.ADDRESS],
      // email: data[Form_Strings.EMAIL],
      coordinates: [data[STRINGS.LONGITUDE], data[STRINGS.LATITUDE]],
      contactNumber: data[Form_Strings.PHONE_NUMBER],
      webLink: data[Form_Strings.WEBSITE]
    }

    dispatch(addStayEat(formData, (message, type) => {

      props.enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        }
      });
      if (type == "success") {
        history.push(ROUTE_CONSTANTS.STAY_HOME);
        dispatch(getStayHome());
      }
    }));
  }

  const validationSchema = Yup.object({
    [STRINGS.NAME]: Yup.string().min(2, "Minimum Length is 2").required("This is a required field").max(50, "Maximum Length is 50"),
    [STRINGS.ADDRESS]: Yup.string().min(2, "Minimum Length is 2").required("This is a required field").max(80, "Maximum Length is 80"),
    // [STRINGS.DESCRIPTION]: Yup.string().min(2, "Minimum Length is 2").required("This is a required field"),
    [STRINGS.PHONE_NUMBER]: Yup.string().required(STRINGS.PHONE_EMPTY).matches(REGEX.PHONE, STRINGS.PHONE_ERROR),
    // [STRINGS.EMAIL]: Yup.string().required(STRINGS.EMAIL_EMPTY).email(STRINGS.EMAIL_ERROR),
    [STRINGS.LATITUDE]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.LONGITUDE]: Yup.string().required(STRINGS.EMPTY_FIELD),
    Trail: Yup.array()
      .min(1, STRINGS.EMPTY_FIELD)
      .required(STRINGS.EMPTY_FIELD),
    [STRINGS.STAY_EAT_IMAGE]: Yup.string().required(STRINGS.EMPTY_FIELD)
  })

  useEffect(() => {
    dispatch(gettrails())
  }, [])


  return (
    <>
      <Formik
        onSubmit={submitHandler}
        initialValues={{
          [STRINGS.NAME]: STRINGS.EMPTY_STRING,
          [STRINGS.ADDRESS]: STRINGS.EMPTY_STRING,
          // [STRINGS.DESCRIPTION]: STRINGS.EMPTY_STRING,
          [STRINGS.PHONE_NUMBER]: STRINGS.EMPTY_STRING,
          // [STRINGS.EMAIL]: STRINGS.EMPTY_STRING,
          [STRINGS.LATITUDE]: STRINGS.EMPTY_STRING,
          [STRINGS.LONGITUDE]: STRINGS.EMPTY_STRING,
          [STRINGS.TRAIL]: STRINGS.EMPTY_STRING,
          [STRINGS.STAY_EAT_IMAGE]: STRINGS.EMPTY_STRING
        }}
        validationSchema={validationSchema}
      >
        {((props) =>

          <Form>
            <div className="d-flex justify-content-between flex-wrap w-100 page-title">
              <h4 className="h4">Stay or Eat</h4>
            </div>
            <div className="form-group">
              <TextField
                label={LABELS.NAME}
                placeholder={PLACEHOLDERS.WRITE_HERE}
                name={STRINGS.NAME}
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Image</label>
              <File_Field
                name={STRINGS.STAY_EAT_IMAGE}
                placeholder=""
                label="Upload Image"
                setFieldValue={props.setFieldValue}
                id="hotel"
                fileName="Hotel"
              />
            </div>
            <div className="form-group">
              <label>Select Trail</label>
              <Multi_Select_Dropdown
                name="Trail"
                defaultValue=""
                options={allTrails}
                placeholder={PLACEHOLDERS.SELECT}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                changeHandler={(e) => {
                  props.setFieldValue("Trail", e);

                }}
              />
            </div>
            <div className="form-group">
              <TextField
                label={STRINGS.LATITUDE}
                placeholder={PLACEHOLDERS.WRITE_HERE}
                name={STRINGS.LATITUDE}
                type="number"
              />
            </div>
            {/* </div> */}
            {/* <div className="col-md-4"> */}
            <div className="form-group">
              <TextField
                label={STRINGS.LONGITUDE}
                placeholder={PLACEHOLDERS.WRITE_HERE}
                name={STRINGS.LONGITUDE}
                type="number"
              />
            </div>
            <div className="form-group">
              <TextField
                label={LABELS.ADDRESS}
                placeholder={PLACEHOLDERS.WRITE_HERE}
                name={STRINGS.ADDRESS}
                type="text"
              />
            </div>
            {/* <div className="form-group">
            <TextField
              label={LABELS.DESCRIPTION}
              placeholder={PLACEHOLDERS.WRITE_HERE}
              name={STRINGS.DESCRIPTION}
              type="text"
            />
          </div> */}
            <div className="form-group">
              <TextField
                label={LABELS.PHONE_NUMBER}
                placeholder={PLACEHOLDERS.WRITE_HERE}
                name={STRINGS.PHONE_NUMBER}
                type="tel"
              />
            </div>
            {/* <div className="form-group">
            <TextField
              label={LABELS.EMAIL}
              placeholder={PLACEHOLDERS.WRITE_HERE}
              name={STRINGS.EMAIL}
              type="text"
            />
          </div> */}
            <div className="form-group">
              <TextField
                label={LABELS.WEBSITE}
                placeholder={PLACEHOLDERS.WRITE_HERE}
                name={STRINGS.WEBSITE}
                type="text"
              />
            </div>
            <div className="btn_group pb-3">
              <button className="btn btn-md btn-cancel m-1" type="button" onClick={() => history.push(ROUTE_CONSTANTS.STAY_HOME)}>
                Cancel
              </button>
              <button className="btn btn-md btn-primary m-1" type="submit">
                Add Stay Eat
              </button>
            </div>
          </Form>

        )}
      </Formik>
    </>
  )

}

export default withSnackbar(Stay_Eat_Screen);