import ServerImage from "Components/atoms/Server_Image";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  deleteReview,
  deleteStayEat,
  getStayHome,
} from "Redux/Actions/commonCRUD";
import { Capitalize, ROUTE_CONSTANTS, STRINGS, titleCase } from "Shared";
import { withSnackbar } from "notistack";

function Stay_Eat_Details(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const current_stay_eat = useSelector(
    (state) => state.currentStayEat.currentStayEat
  );

  const deleteHandler = () => {
    let id = current_stay_eat?._id;
    dispatch(
      deleteStayEat(id, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          dispatch(getStayHome());
          history.push(ROUTE_CONSTANTS.STAY_HOME);
        }
      })
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h4 className="h4">Stay or Eat</h4>
        <div className="col_rht">
          <button className="btn btn-sm btn-primary" onClick={()=>{
            history.push(ROUTE_CONSTANTS.EDIT_STAY_EAT)
          }}>Edit</button>
          <button
            className="btn btn-sm btn-cancel"
            onClick={() => deleteHandler()}
          >
            Delete
          </button>
        </div>
      </div>
      <div>
        <>
          <div className="photos_list">
            <div className="photo_items">
              <figure>
                <ServerImage url={current_stay_eat?.imageUrl} />
              </figure>
            </div>
          </div>

          <div className="basic_info">
            <h4>Basic Info</h4>
            <div className="row">
              <div className="col-md-4">
                <div className="info_block">
                  <label>{STRINGS.NAME}</label>
                  <span>{Capitalize(current_stay_eat?.name)}</span>
                </div>
              </div>

              <div className="col-md-12">
                <div className="info_block">
                  <label>{STRINGS.TRAIL_ADDRESS}</label>
                  <span>{Capitalize(current_stay_eat?.address)}</span>
                </div>
              </div>

              {/* <div className="col-md-12">
                <div className="info_block">
                  <label>{STRINGS.DESCRIPTION}</label>
                  <p>{titleCase(current_stay_eat?.description)}</p>
                </div>
              </div> */}

              {/* <div className="col-md-12">
                <div className="info_block">
                  <label>{STRINGS.EMAIL}</label>
                  <p>{titleCase(current_stay_eat?.email)}</p>
                </div>
              </div> */}

              <div className="col-md-12">
                <div className="info_block">
                  <label>{STRINGS.CONTACT_NUMBER}</label>
                  <p>{current_stay_eat?.contactNumber}</p>
                </div>
              </div>

              <div className="col-md-12">
                <div className="info_block">
                  <label>{STRINGS.LATITUDE}</label>
                  <p>{ current_stay_eat?.coordinates ? current_stay_eat?.coordinates[1] : " --"}</p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="info_block">
                  <label>{STRINGS.LONGITUDE}</label>
                  <p>{current_stay_eat?.coordinates ? current_stay_eat?.coordinates[0] : " --"}</p>
                </div>
              </div>

              <div className="col-md-12">
                <div className="info_block">
                  <label>{STRINGS.WEBSITE}</label>
                  <p>
                    {current_stay_eat?.webLink
                      ? current_stay_eat?.webLink
                      : "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default withSnackbar(Stay_Eat_Details);
