import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "Redux/Actions/filterCRUD";
import moment from "moment";
import "./style.scss";

import {
  deleteCategory,
  postCategories,
  sortCategories,
  updateCategory,
} from "Redux/Actions/commonCRUD";
import { titleCase } from "Shared";
import CustomModal from "Components/atoms/Modal";
import TextField from "Components/atoms/TextField";
import { ICONS } from "Shared/Images";
import { withSnackbar } from "notistack";
import capitalize from "@mui/utils/capitalize";
import { useHistory, useLocation } from "react-router";
import ReactPaginate from "react-paginate";
import SingleSelectDropdown from "Components/atoms/SingleSelectDropdown";
import DraggableList from "react-draggable-lists";

function Add_Categories(props) {
  const [category, setCategory] = useState("");
  const trail_categories = useSelector((state) => state.category.category);
  const [index, setIndex] = useState();
  const [modalOpen, setmodalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [showEditModal, setModalView] = useState(false);
  const [edit_category, set_edit_Category] = useState("");
  const [update_category_Id, set_update_category_Id] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentItems, setCurrentItems] = useState(items);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState(trail_categories);
  const itemsPerPage = 10;
  const [defaultSortValue, setDefaultSortValue] = useState([]);

  const [count, setCount] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    dispatch(getCategories());
  };

  const deleteHandler = (index) => {
    let temp_categories = [...items];
    dispatch(
      deleteCategory(index, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          dispatch(getCategories());
          // setItems(trail_categories);
        }
      })
    );
  };

  const sortingOptions = [
    { value: 3, label: "Recently added" },
    { value: 1, label: "Alphabetically (A-Z)" },
    { value: 2, label: "Alphabetically (Z-A)" },
  ];

  const submitHandler = () => {
    let data = {
      name: category,
    };
    if (!category.length) {
      setError(true);
    }
    else if (category.length) {
      dispatch(
        postCategories(data, (message, type) => {
          setError(false);
          props.enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
          if (type == "success") {
            dispatch(getCategories());
            setItems(trail_categories);
            setCategory("");
            toggleModalView();
          }
        })
      );
    }
  };

  const sortHandler = (val) => {
    let data = {
      data: val?.value,
    };
    dispatch(
      sortCategories(data, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          setError(false);
          dispatch(getCategories());
        }
      })
    );
  };

  const clearHandler = () => {
    setError(false);
  }

  const updateHandler = () => {

    if (!edit_category?.length) {
      setError(true);
    }

    else if (edit_category?.length) {
      let data = {
        id: update_category_Id,
        name: edit_category,
      };
      dispatch(
        updateCategory(data, (message, type) => {
          props.enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
          if (type == "success") {
            dispatch(getCategories());
            setItems(trail_categories);
            setError(false);
            toggleModalView();
          }
        })
      );
    }
  };

  const addHandler = () => {
    setModalView(false);
    toggleModalView();
  };

  const editHandler = (index) => {
    let current_category = trail_categories.filter(
      (value) => value._id === index
    );
    setModalView(true);
    toggleModalView();
    setIndex(index);
    set_update_category_Id(index);
    set_edit_Category(current_category[0]?.name);
  };

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const toggleModalView = () => {
    setmodalOpen((modalOpen) => !modalOpen);
    setCategory("")
  };


  useEffect(() => {
    setItems(trail_categories);
    setDefaultSortValue(
      sortingOptions?.filter(
        (value) => value?.value === trail_categories[0]?.categorySort
      )
    );
  }, [trail_categories]);
  return (
    <>
      <CustomModal isOpen={modalOpen} handleToggle={toggleModalView} clearHandler={clearHandler}>
        {!showEditModal ? (
          <>
            <div className="modal-header justify-content-center">
              <h3 className="h4">Add New Trail Category</h3>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <label>Name</label>
                  <div className="form-group">
                    <input
                      placeholder="Write Here ..."
                      name="Category"
                      type="text"
                      value={titleCase(category)}
                      className="form-control"
                      onChange={(e) => {
                        setError(false)
                        setCategory(e.target.value)}}
                    />
                    {error ? (
                      <span className="error">This is a required field</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer justify-content-center">
              <button
                className="btn btn-md btn-cancel"
                onClick={() => toggleModalView()}
              >
                Cancel
              </button>
              <button
                className="btn btn-md btn-primary"
                onClick={() => {
                  submitHandler();
                }}
              >
                Add
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="modal-header justify-content-center">
              <h3>Edit Category</h3>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <label></label>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      className="form-control"
                      placeholder="Write Here ..."
                      name="Category"
                      type="text"
                      value={titleCase(edit_category)}
                      onChange={(e) => set_edit_Category(e.target.value)}
                    />
                    {error ? (
                      <span className="error" a>
                        This is a Required Field
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer justify-content-center">
              <button
                className="btn btn-md btn-cancel"
                onClick={() => {
                  toggleModalView()
                  setError(false)
                }
                }
              >
                Cancel
              </button>
              <button
                className="btn btn-md btn-primary"
                onClick={() => {
                  updateHandler();
                }}
              >
                Update
              </button>
            </div>
          </>
        )}
      </CustomModal>

      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h2 className="h4">Trail Category</h2>

        <div className="col_rht d-flex align-items-center">
          <div className="form-group mb-0">
            <div className="d-flex align-items-center">
              <label className="sort-sec">Sort by</label>
              <SingleSelectDropdown
                id={defaultSortValue[0]?.value}
                options={sortingOptions}
                defaultValue={defaultSortValue[0]}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                changeHandler={(e) => {
                  sortHandler(e);
                }}
              />
            </div>
            {/* <label>This sorting would reflect on App side</label> */}
          </div>
          <button
            onClick={() => addHandler()}
            className="btn btn-sm btn-primary"
          >
            Add Category
          </button>
        </div>
      </div>

      <div class="table-responsive custom_table">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Name</th>
              <th>Created Date</th>
              <th>Action</th>
            </tr>
          </thead>
  
            {currentItems?.map((value) => (
              <tbody index={value._id}>
                <tr>
                  <td>{capitalize(value.name)}</td>
                  <td>
                    <span className="time">
                      {moment(value.createdAt).format("DD-MMM-YYYY")}
                    </span>
                  </td>

                  <td>
                    <div class="action_group">
                      <button class="btn" onClick={() => editHandler(value._id)}>
                        <img src={ICONS.EditIcon} alt="Edit Icon" />
                      </button>
                      <button
                        class="btn"
                        onClick={() => deleteHandler(value._id)}
                      >
                        <img src={ICONS.RemoveIcon} alt="Remove Icon" />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}

        </table>
      </div>


      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}
export default withSnackbar(Add_Categories);
