import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTrail, gettrails, setLocation } from "Redux/Actions/commonCRUD";
import { ICONS, ROUTE_CONSTANTS, STRINGS, titleCase } from "Shared";
import { useHistory } from "react-router";
import { all } from "@redux-saga/core/effects";
// import "./style.scss";
import ReactPaginate from "react-paginate";
import ServerImage from "Components/atoms/Server_Image";
import { RatingStar } from "rating-star";
import Rating from "Components/atoms/Rating";
import StarRatings from "react-star-ratings";
import moment from "moment";
import GpxMap from "Components/atoms/Map";

function ViewUserActivity() {
  const current_userActivity = useSelector((state) => state?.userActivity?.Current_UserActivity);
  const [mapPoints, setMapPoints] = useState([]);
  const dispatch = useDispatch();
  const getWebURL = (url) => {
    if (url?.substr(0, 5) == "https") {
      return url;
    }
    return `https://${url}`;
  };

  useEffect(() => {
    document.getElementById("map").classList.add("visible");
    document.getElementById("map_close_btn").classList.add("visible");
    document.getElementById("map-sec").classList.add("visible");

    return () => {
      dispatch(setLocation([]));
      document.getElementById("map").classList.remove("visible");
      document.getElementById("map_close_btn").classList.remove("visible");
    };
  }, []);

  useEffect(() => {
    let coordinates =
      current_userActivity?.route?.length &&
      current_userActivity?.route.map((value) =>
        Object.assign(
          {},
          {
            lat: value?.latitude,
            lng: value?.longitude,
          }
        )
      );

    setMapPoints(coordinates);
  }, [current_userActivity]);

  return (
    <>
      <div>
        {current_userActivity ? (
          <div>
            <div className="d-flex justify-content-between flex-wrap w-100 page-title">
              <h4 className="h4">{current_userActivity?.activity ? current_userActivity?.activity[0] : ""}</h4>
            </div>
            <div className="">
              <div className="photo_items">
                <img
                  src={getWebURL(current_userActivity?.user?.profileUrl)}
                  alt="profile"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
              <div>
                <h4>{current_userActivity?.user?.firstName + " " + current_userActivity?.user?.lastName}</h4>
              </div>
            </div>

            <fieldset>
              <legend>Details</legend>
              <div className="basic_info">
                <div className="row">
                  <div className="col-md-4">
                    <div className="info_block">
                      <label>{STRINGS.ELEVATION_GAIN}</label>
                      <span>{current_userActivity?.elevationGain}</span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="info_block">
                      <label>{STRINGS.AVERAGE_SPEED}</label>
                      <span>{`${current_userActivity?.avgSpeed} KMPH `}</span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="info_block">
                      <label>{STRINGS.DISTANCE}</label>
                      <span>{`${current_userActivity?.distance} KM `}</span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="info_block">
                      <label>{STRINGS.TIME}</label>
                      <span>{`${current_userActivity?.totalTime}  `}</span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="info_block">
                      <label>{STRINGS.MOVINGTIME}</label>
                      <span>{`${current_userActivity?.movingTime}  `}</span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="info_block">
                      <label>{STRINGS.CALORIES}</label>
                      <span>{`${current_userActivity?.calories}  `}</span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="info_block">
                      <label>{STRINGS.COMPLETED_AT}</label>
                      <span>{moment(current_userActivity?.completedAt).format("DD-MMM-YYYY")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div id="userMap"></div>
            <GpxMap points={mapPoints} />
          </div>
        ) : (
          <center>
            <h3>NO Data</h3>
          </center>
        )}
      </div>
    </>
  );
}

export default ViewUserActivity;
