export const GET_FILTERS = "GET_FILTERS";
export const SET_FILTERS = "SET_FILTERS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const POST_CATEGORIES = "POST_CATEGORIES"
export const ADD_TRAIL = "ADD_TRAIL";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const GET_TRAILS = "GET_TRAILS";
export const SET_TRAILS = "SET_TRAILS";
export const ADD_STAY_EAT = "ADD_STAY_EAT";
export const POST_FILTERS = "POST_FILTERS";
export const POST_DAYS_OUT = "POST_DAYS_OUT";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const SET_LOCATION = "SET_LOCATION";
export const GET_TRAIL = "GET_TRAIL";
export const SET_TRAIL = "SET_TRAIL";
export const SET_AUTHTOKEN = "SET_AUTHTOKEN";
export const SEND_OTP = "SEND_OTP";
export const SET_EMAIL = "SET_EMAIL";
export const VERIFY_OTP = "VERIFY_OTP";
export const SET_USER_ID = "SET_USER_ID";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_IMAGE_URL = "GET_IMAGE_URL";
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const RESEND_OTP = "RESEND_OTP";
export const DELETE_TRAIL = "DELETE_TRAIL";
export const UPDATE_TRAIL = "UPDATE_TRAIL";
export const DELETE_REVIEW = "DELETE_REVIEW";
export const DELETE_PHOTOS = "DELETE_PHOTOS";
export const GET_STAY_HOME = "GET_STAY_HOME";
export const SET_STAY_HOME = "SET_STAY_HOME";
export const GET_STAY_EAT_DETAILS = "GET_STAY_EAT_DETAILS";
export const SET_CURRENT_STAY_HOME = "SET_CURRENT_STAY_HOME";
export const DELETE_STAY_EAT = "DELETE_STAY_EAT";
export const GET_DAYS_OUT = "GET_DAYS_OUT";
export const SET_DAYS_OUT = "SET_DAYS_OUT";
export const GET_DAYS_OUT_DETAILS = "GET_DAYS_OUT_DETAILS";
export const SET_DAYS_OUT_DETAILS = "SET_DAYS_OUT_DETAILS";
export const DELETE_DAYS_OUT = "DELETE_DAYS_OUT";
export const GET_EQUIPMENTS = "GET_EQUIPMENTS";
export const SET_EQUIPMENTS = "SET_EQUIPMENTS";
export const POST_EQUIPMENTS = "POST_EQUIPMENTS";
export const DELETE_EQUIPMENTS = "DELETE_EQUIPMENTS";
export const UPDATE_EQUIPMENTS = "UPDATE_EQUIPMENTS";
export const SET_MULTIPLE_IMAGES = "SET_MULTIPLE_IMAGES";
export const UPLOAD_MULTIPLE_IMAGES = "UPLOAD_MULTIPLE_IMAGES";
export const GET_PROFILES = "GET_PROFILES";
export const SET_PROFILES = "SET_PROFILES";
export const POST_PROFILES = "POST_PROFILES";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const POST_NOTIFICATIONS = "POST_NOTIFICATIONS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const GET_UPDATED_NOTIFICATIONS = "GET_UPDATE_NOTIFICATIONS";
export const APPROVE_IMAGE = "APPROVE_IMAGE";
export const DENY_IMAGE = "DENY_IMAGE";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const SET_ACTIVITY = "SET_ACTIVITY";
export const POST_ACTIVITY = "POST_ACTIVITY";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const SORT_IMAGE = "SORT_IMAGE";
export const UPDATE_DAYSOUT = "UPDATE_DAYSOUT";
export const UPDATE_STAYEAT = "UPDATE_STAYEAT";
export const MULTIPLE_FILE_UPLOAD = "MULTIPLE_FILE_UPLOAD";
export const POST_OTHER_FACILITIES = "POST_OTHER_FACILITIES";
export const GET_OTHER_FACILITIES = "GET_OTHER_FACILITIES";
export const SET_OTHER_FACILITIES = "SET_OTHER_FACILITIES";
export const SET_NEARBY_ATTRACTIONS = "SET_NEARBY_ATTRACTIONS";
export const SET_GARAGES = "SET_GARAGES";
export const SET_PUBLIC_TRANSPORT = "SET_PUBLIC_TRANSPORT";
export const SET_EMERGENCY_NUMBERS = "SET_EMERGENCY_NUMBERS";
export const SET_BIKE_REPAIR = "SET_BIKE_REPAIR";
export const SET_CURRENT_NEARBY_ATTRACTION = "SET_CURRENT_NEARBY_ATTRACTION";
export const SET_CURRENT_GARAGE = "SET_CURRENT_GARAGE";
export const SET_CURRENT_PUBLIC_TRANSPORT = "SET_CURRENT_PUBLIC_TRANSPORT";
export const SET_CURRENT_BIKE_REPAIR = "SET_CURRENT_BIKE_REPAIR";
export const DELETE_OTHER_FACILITIES = "DELETE_OTHER_FACILITIES";
export const SORT_CATEGORIES = "SORT_CATEGORIES";
export const SORT_DAYSOUT = "SORT_DAYSOUT";
export const GET_TRAIL_BY_LANGUAGE = "GET_TRAIL_BY_LANGUAGE";
export const GET_FILTERS_BY_LANGUAGE = "GET_FILTERS_BY_LANGUAGE";
export const GET_CATEGORIES_BY_LANGUAGE = "GET_CATEGORIES_BY_LANGUAGE"
export const GET_STAY_EAT_BY_LANGUAGE = "GET_STAY_EAT_BY_LANGUAGE";
export const GET_DAYS_OUT_BY_LANGUAGE = "GET_DAYS_OUT_BY_LANGUAGE";
export const GET_PROFILES_BY_LANGUAGE = "GET_PROFILES_BY_LANGUAGE";
export const POST_EMERGENCY_NUMBER = "POST_EMERGENCY_NUMBER";
export const EDIT_EMERGENCY_NUMBER = "EDIT_EMERGENCY_NUMBER";
export const DELETE_EMERGENCY_NUMBER = "DELETE_EMERGENCY_NUMBER";
export const UPDATE_OTHER_FACILITIES = "UPDATE_OTHER_FACILITIES";
export const SET_CURRENT_FACILITY = "SET_CURRENT_FACILITY";
export const GET_ALL_OTHER_FACILTIES = "GET_ALL_OTHER_FACILTIES";
export const GET_ADVERTISEMENT = "GET_ADVERTISEMENT";
export const SET_ADVERTISEMENT = "SET_ADVERTISEMENT";
export const POST_ADVERTISEMENT = "POST_ADVERTISEMENT";
export const UPDATE_ADVERTISEMENT = "UPDATE_ADVERTISEMENT";
export const DELETE_ADVERTISEMENT = "DELETE_ADVERTISEMENT";

export const GET_USER_ACTIVITIES = "GET_USER_ACTIVITIES";
export const SET_USER_ACTIVITIES = "SET_USER_ACTIVITIES";
export const DELETE_USER_ACTIVITY = "DELETE_USER_ACTIVITY";

export const SET_CURRENT_USERACTIVITY = "SET_CURRENT_USERACTIVITY";
export const SET_COORDINATES = "SET_COORDINATES";
export const GET_COORDINATES = "GET_COORDINATES";
export const SET_GPXFILE_NAME = "SET_GPXFILE_NAME";



