export const ROUTE_CONSTANTS={
    LOGIN:"/login",
    FORGOT_PASSWORD:"/forgot_password",
    RESET_PASSWORD: "/reset_password",
    ADMIN_ADD_TRAIL:"/admin/add_trail",
    ADMIN_ADD_STAY_OR_EAT:"/admin/add_stay_or_eat",
    ADMIN_ADD_CATEGORIES:"/admin/add_categories",
    ADMIN_ADD_FILTERS:"/admin/add_filters",
    ADMIN_ADD_DAYS_OUT:"/admin/add_days_out",
    ADMIN_TRAILS:"/admin/trails",
    MAP:"/admin/map",
    TRAIL_INFO:"/admin/trail/:id",
    OTP_VERIFICATION:"/otp_verification",
    EDIT_TRAIL:"/admin/edit_trail",
    VIEW_USERACTIVITY:"/admin/viewUserActivity",
    STAY_HOME:"/admin/stayOrEat/home",
    STAY_EAT_INFO:"/admin/stayoreat/:id",
    DAYS_OUT_HOME:"/admin/daysOut/home",
    DAYS_OUT_INFO:"/admin/daysOut/:id",
    EDIT_STAY_EAT:"/admin/stayEat/edit",
    EDIT_DAYS_OUT:"/admin/daysOut/edit",
    EQUIPMENTS:"/admin/equipments",
    PROFILE:"/admin/profile",
    ACTIVITY:"/admin/activity",
    ADD_OTHER_FACILTITES: "/admin/add_other_facilities",
    NEARBY_ATTRACTIONS_HOME: "/admin/nearby_attractions_home",
    GARAGES_HOME:"/admin/garages_home",
    BIKE_REPAIR_HOME:"/admin/bike_repair_home",
    PUBLIC_TRANSPORT_HOME:"/admin/public_transport_home",
    NEARBY_ATTRACTIONS_INFO:"/admin/nearbyAttraction/:id",
    BIKE_REPAIR_INFO:"/admin/bikeRepair/:id",
    PUBLIC_TRANSPORT_INFO:"/admin/publicTransport/:id",
    GARAGE_INFO:"/admin/garage/:id",
    EMERGENCY_NUMBERS:"/admin/emergencyNumbers",
    EDIT_OTHER_FACILTIES:"/admin/otherFacilities/edit",
    ADVERTISEMENT: "/admin/advertisement"
}