import{ GET_ADVERTISEMENT, SET_ADVERTISEMENT } from "../Actions/ActionType"

const initialState={
    advertisement:[]
}

function adReducer(state=initialState,action){
    
    switch(action.type){
        case GET_ADVERTISEMENT:
            return state;

            case SET_ADVERTISEMENT:
                return {
                    ...state,
                    advertisement: action.payload
                }
   
               default:
                   return state;
    }
}

export default adReducer;