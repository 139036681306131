// import GpxMap from "Components/atoms/Map";
import TextField from "Components/atoms/TextField";
import Nav_Bar from "Components/hoc/NavBar";
import react from "react";
import Add_Trail from "./form";

function Add_Trail_Screen() {
  return (
    <>
      {/* <Nav_Bar/> */}
      <Add_Trail />
      {/* <GpxMap/> */}
    </>
  );
}
export default Add_Trail_Screen;
