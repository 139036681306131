import capitalize from "@mui/utils/capitalize";
import File_Field from "Components/atoms/FileField";
import CustomModal from "Components/atoms/Modal";
import TextField from "Components/atoms/TextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form_Strings, ICONS, IMAGES, LABELS, PLACEHOLDERS, STRINGS, REGEX, titleCase } from "Shared";
import { withSnackbar } from "notistack";
import {
  deleteAdvertisement,
  getAdvertisement,
  postAdvertisement,
  updateAdvertisement,
} from "Redux/Actions/commonCRUD";

function Advertisement(props) {
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const [showEditModal, setModalView] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState({});
  const advertisement = useSelector((state) => state.advertisement.advertisement);
  const dispatch = useDispatch();

  const addHandler = () => {
    setModalView(false);
    toggleModalView();
  };

  const deleteHandler = (id) => {
    let data = {
      id: id,
    };
    dispatch(
      deleteAdvertisement(data, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          dispatch(getAdvertisement());
        }
      })
    );
  };

  const toggleModalView = () => {
    setmodalOpen((modalOpen) => !modalOpen);
  };

  const toggleEditModalView = (data) => {
    setEditModalOpen((editModalOpen) => !editModalOpen);
    setEditModalData(data);
  };
  const imageHandler = () => {
    setData(null);
  };
  const submitHandler = (data) => {
    let values = {
      name: data[Form_Strings.NAME],
      image: data[STRINGS.FACILITIES_IMAGE],
      coordinates: [data[STRINGS.LONGITUDE], data[STRINGS.LATITUDE]],
      webLink: data[Form_Strings.WEBSITE],
      contactNumber: data[Form_Strings.PHONE_NUMBER],
    };
    dispatch(
      postAdvertisement(values, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          setModalView(true);
          toggleModalView();
          dispatch(getAdvertisement());
        }
      })
    );
  };

  const editSubmitHandler = (data) => {
    let formData = {
      id: editModalData?._id,
      name: data[Form_Strings.NAME],
      image: data[STRINGS.FACILITIES_IMAGE],
      coordinates: [data[STRINGS.LONGITUDE], data[STRINGS.LATITUDE]],
      webLink: data[Form_Strings.WEBSITE],
      contactNumber: data[Form_Strings.PHONE_NUMBER],
    };

    dispatch(
      updateAdvertisement(formData, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          setModalView(true);
          toggleEditModalView();
          dispatch(getAdvertisement());
        }
      })
    );
  };

  useEffect(() => {
    dispatch(getAdvertisement());
  }, []);

  const validationSchema = Yup.object({
    [STRINGS.NAME]: Yup.string()
      .min(2, "Minimum Length is 2")
      .required("This is a required field")
      .max(50, "Maximum Length is 50"),
    [STRINGS.PHONE_NUMBER]: Yup.string().required(STRINGS.PHONE_EMPTY).matches(REGEX.PHONE, STRINGS.PHONE_ERROR),
    [STRINGS.LATITUDE]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.LONGITUDE]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.FACILITIES_IMAGE]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.WEBSITE]: Yup.string().required(STRINGS.EMPTY_FIELD),
  });

  return (
    <>
      <CustomModal isOpen={modalOpen} handleToggle={toggleModalView}>
        <Formik
          onSubmit={submitHandler}
          initialValues={{
            [STRINGS.NAME]: STRINGS.EMPTY_STRING,
            [STRINGS.ADDRESS]: STRINGS.EMPTY_STRING,
            [STRINGS.PHONE_NUMBER]: STRINGS.EMPTY_STRING,
            [STRINGS.LATITUDE]: STRINGS.EMPTY_STRING,
            [STRINGS.LONGITUDE]: STRINGS.EMPTY_STRING,
            [STRINGS.WEBSITE]: STRINGS.EMPTY_STRING,
            [STRINGS.FACILITIES_IMAGE]: STRINGS.EMPTY_STRING,
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <div className="modal-header justify-content-center">
                <h3 className="h4">Add Advertisement</h3>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    {/* <label>Name</label> */}
                    <div className="form-group">
                      {/* <TextField
                                                label={STRINGS.NAME}
                                                name="Equipments"
                                                type="text"
                                                className="form-control"
                                            /> */}
                      <TextField
                        label={LABELS.NAME}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.NAME}
                        type="text"
                      />
                      {error ? <span className="error">This is a required field</span> : null}
                    </div>
                    <div className="form-group">
                      <label>Image</label>
                      <File_Field
                        // name={STRINGS.EQUIPMENT}
                        name={STRINGS.FACILITIES_IMAGE}
                        setFieldValue={props.setFieldValue}
                        id="trail"
                        showmap={false}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        label={STRINGS.LATITUDE}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.LATITUDE}
                        type="number"
                      />
                    </div>
                    {/* </div> */}
                    {/* <div className="col-md-4"> */}
                    <div className="form-group">
                      <TextField
                        label={STRINGS.LONGITUDE}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.LONGITUDE}
                        type="number"
                      />
                    </div>

                    <div className="form-group">
                      <TextField
                        label={LABELS.PHONE_NUMBER}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.PHONE_NUMBER}
                        type="tel"
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        label={LABELS.WEBSITE}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.WEBSITE}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-md btn-cancel" onClick={() => toggleModalView()}>
                  Cancel
                </button>
                <button className="btn btn-md btn-primary" type="submit">
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModal>

      <CustomModal isOpen={editModalOpen} handleToggle={toggleEditModalView}>
        <Formik
          onSubmit={editSubmitHandler}
          initialValues={{
            [STRINGS.NAME]: editModalData?.name,
            [STRINGS.EQUIPMENT]: editModalData?.image,
            [STRINGS.LATITUDE]: editModalData?.coordinates ? editModalData?.coordinates[1] : "",
            [STRINGS.LONGITUDE]: editModalData?.coordinates ? editModalData?.coordinates[0] : "",
            [STRINGS.WEBSITE]: editModalData?.webLink,
            [STRINGS.PHONE_NUMBER]: editModalData?.contactNumber,
            [STRINGS.FACILITIES_IMAGE]: editModalData?.image,
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <div className="modal-header justify-content-center">
                <h3 className="h4">Edit Advertisement</h3>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    {/* <label>Name</label> */}
                    <div className="form-group">
                      {/* <TextField
                                                label="Name"
                                                name={STRINGS.NAME}
                                                // setFieldValue={editModalData?.name}
                                                type="text"
                                                className="form-control"
                                            /> */}
                      <TextField
                        label={LABELS.NAME}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.NAME}
                        type="text"
                      />
                      {error ? <span className="error">This is a required field</span> : null}
                    </div>
                    <label>Image</label>
                    <File_Field
                      // name={STRINGS.EQUIPMENT}
                      name={STRINGS.FACILITIES_IMAGE}
                      setFieldValue={props.setFieldValue}
                      id="trail"
                      showmap={false}
                      showImage={false}
                      ImageUrl={editModalData?.image}
                      clickHandler={imageHandler}
                      showmapIcon={false}
                      fileName="Trail"
                    />
                    <div className="form-group">
                      <TextField
                        label={STRINGS.LATITUDE}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.LATITUDE}
                        type="number"
                      />
                    </div>

                    <div className="form-group">
                      <TextField
                        label={STRINGS.LONGITUDE}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.LONGITUDE}
                        type="number"
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        label={LABELS.PHONE_NUMBER}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.PHONE_NUMBER}
                        type="tel"
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        label={LABELS.WEBSITE}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.WEBSITE}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-md btn-cancel" onClick={() => toggleEditModalView()}>
                  Cancel
                </button>
                <button className="btn btn-md btn-primary" type="submit">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModal>

      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h2 className="h4">Advertisement</h2>

        <div className="col_rht">
          <button onClick={() => addHandler()} className="btn btn-sm btn-primary">
            Add
          </button>
        </div>
      </div>

      {/* <div class="table-responsive custom_table">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>

          {currentItems?.map((value) => (
            <tbody index={value?._id}>
              <tr>
                <td>
                  <ServerImage
                    url={value?.imagePath}
                    width="50px"
                    height="50px"
                    className="server_image"
                  />
                </td>
                <td>{capitalize(value?.text)}</td>
                <td>
                  <div class="action_group">
                    <button
                      class="btn"
                      onClick={() => editHandler(value)}
                    >
                      <img src={ICONS.EditIcon} alt="Edit Icon" />
                    </button>
                    <button
                      class="btn"
                        onClick={() => deleteHandler(value?.key)}
                    >
                      <img src={ICONS.RemoveIcon} alt="Remove Icon" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div> */}
      {advertisement && advertisement?.length ? (
        <div className="photos_container">
          {advertisement ? (
            advertisement.map((value) => (
              <div className="photo_container_img col-md-3">
                <figure>
                  <img className="photos" src={`https://${value.imageUrl}`} />
                </figure>
                <div class="edit-pic">
                  <button class="btn" onClick={() => toggleEditModalView(value)}>
                    <img src={IMAGES.EDIT_BUTTON} alt="Edit Icon" />
                  </button>
                </div>
                <div class="delete-pic">
                  <button class="btn" onClick={() => deleteHandler(value._id)}>
                    <img src={IMAGES.TRASH_ICON} alt="Remove Icon" />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <h1>No Advertisement Found</h1>
          )}
        </div>
      ) : (
        <h1>No Advertisement Found</h1>
      )}
    </>
  );
}

export default withSnackbar(Advertisement);
