import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, setCategories } from "Redux/Actions/filterCRUD";
import moment from "moment";
import Custom_Modal from "Components/atoms/Modal";

import {
  deleteCategory,
  getActivity,
  postActivity,
  postCategories,
  updateActivity,
  updateCategory,
} from "Redux/Actions/commonCRUD";
import { titleCase } from "Shared";
import CustomModal from "Components/atoms/Modal";
import TextField from "Components/atoms/TextField";
import { ICONS } from "Shared/Images";
import { withSnackbar } from "notistack";
import capitalize from "@mui/utils/capitalize";
import { useHistory, useLocation } from "react-router";
import ReactPaginate from "react-paginate";
// import arraySort from "array-sort";
import { useThemeWithoutDefault } from "@mui/system";
import "./style.scss";

function Activity(props) {
  const [activity, setActivity] = useState("");
  const trail_activities = useSelector((state) => state.activity.activity);
  const [index, setIndex] = useState();
  const [modalOpen, setmodalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [showEditModal, setModalView] = useState(false);
  const [edit_activity, set_edit_activity] = useState("");
  const [update_category_Id, set_update_category_Id] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentItems, setCurrentItems] = useState(items);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState(trail_activities);
  const itemsPerPage = 5;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  useEffect(() => {
    setItems(trail_activities);
  }, [trail_activities]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    dispatch(getCategories());
  };

  const deleteHandler = (index) => {
    let temp_categories = [...items];
    dispatch(
      deleteCategory(index, (message, type) => {
        dispatch(getCategories());
        setItems(trail_activities);
        setError(false);
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      })
    );
  };

  const submitHandler = () => {
    let data = {
      name: activity,
    };
    // console.log(data);
    if (!activity.length) {
      setError(true);
    }
    if (activity.length) {
      dispatch(
        postActivity(data, (message, type) => {
          dispatch(getActivity());
          setItems(trail_activities);
          props.enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
          setActivity("");
          setError(false);
        })
      );
      toggleModalView();
    }
  };

  const updateHandler = () => {
    if (!edit_activity.length) {
      setError(true);
      return
    }
    setError(false)
    let data = {
      id: update_category_Id,
      name: edit_activity,
    };

    dispatch(
      updateActivity(data, (message, type) => {
        dispatch(getActivity());
        setItems(trail_activities);
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      })
    );
    toggleModalView();
  };

  const addHandler = () => {
    setModalView(false);
    toggleModalView();
  };

  const editHandler = (index) => {
    let current_category = trail_activities.filter(
      (value) => value._id === index
    );
    setModalView(true);
    toggleModalView();
    setIndex(index);
    set_update_category_Id(index);
    set_edit_activity(current_category[0]?.name);
  };

  useEffect(() => {
    dispatch(getActivity());
  }, []);

  const toggleModalView = () => {
    setmodalOpen((modalOpen) => !modalOpen);
    setError(false)
    setActivity("")
  };

  return (
    <>
      <CustomModal isOpen={modalOpen} handleToggle={toggleModalView}>
        {!showEditModal ? (
          <>
            <div className="modal-header justify-content-center">
              <h3 className="h4">Add New Activity</h3>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <label>Name</label>
                  <div className="form-group">
                    <input
                      placeholder="Write Here ..."
                      name="Category"
                      type="text"
                      value={titleCase(activity)}
                      className="form-control"
                      onChange={(e) => {
                        setError(false)
                        setActivity(e.target.value)}}
                    />
                    {error ? (
                      <span className="error">This is a required field</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer justify-content-center">
              <button className="btn btn-md btn-cancel" onClick={()=>toggleModalView()}>Cancel</button>
              <button
                className="btn btn-md btn-primary"
                onClick={() => {
                  submitHandler();
                }}
              >
                Add
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="modal-header justify-content-center">
              <h3>Edit Activity</h3>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <label></label>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      className="form-control"
                      placeholder="Write Here ..."
                      name="Category"
                      type="text"
                      value={titleCase(edit_activity)}
                      onChange={(e) => {
                        setError(false)
                        set_edit_activity(e.target.value)}}
                    />
                    {error ? (
                      <span className="error" a>
                        This is a Required Field
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer justify-content-center">
            <button className="btn btn-md btn-cancel" onClick={()=>toggleModalView()}>Cancel</button>
              <button
                className="btn btn-md btn-primary"
                onClick={() => {
                  updateHandler();
                }}
              >
                Update
              </button>
            </div>
          </>
        )}
      </CustomModal>

      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h2 className="h4">Trail Activity</h2>

        <div className="col_rht">
          <button
            onClick={() => addHandler()}
            className="btn btn-sm btn-primary"
          >
            Add Activity
          </button>
        </div>
      </div>

      <div class="table-responsive custom_table">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>Name</th>
              <th>Created Date</th>
              <th>Action</th>
            </tr>
          </thead>

          {currentItems?.map((value) => (
            <tbody index={value._id}>
              <tr>
                <td>{capitalize(value.name)}</td>
                <td>
                  <span className="time">
                    {moment(value.createdAt).format("DD-MMM-YYYY")}
                  </span>
                </td>
                <td>
                  <div class="action_group">
                    <button class="btn" onClick={() => editHandler(value._id)}>
                      <img src={ICONS.EditIcon} alt="Edit Icon" />
                    </button>
                    {/* <button
                      class="btn"
                      onClick={() => deleteHandler(value._id)}
                    >
                      <img src={ICONS.RemoveIcon} alt="Remove Icon" />
                    </button> */}
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>

      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
      <div className="alert alert-warning">
        <i class="fa fa-warning mr-2"></i>
        <span className="warning_message">
        Activities cannot be deleted if they have already been recorded by users.
        </span>
      </div>
    </>
  );
}
export default withSnackbar(Activity);
