import ServerImage from "Components/atoms/Server_Image";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { deleteDaysOut, getDaysOut } from "Redux/Actions/commonCRUD";
import { Capitalize, ROUTE_CONSTANTS, STRINGS, titleCase } from "Shared";
import { withSnackbar } from "notistack";

function Days_Out_Details(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const current_days_out = useSelector(
    (state) => state.currentDaysOut?.currentDaysOut[0]
  );

  const editHandler = () => {
    history.push(ROUTE_CONSTANTS.EDIT_DAYS_OUT)
  };

  const deleteHandler = () => {
    let id = current_days_out?._id;
    dispatch(
      deleteDaysOut(id, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });

        if (type == "success") {
          dispatch(getDaysOut());
          history.push(ROUTE_CONSTANTS.DAYS_OUT_HOME);
        }
      })
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h4 className="h4">Days Out</h4>
        <div className="col_rht">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => editHandler()}
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-cancel"
            onClick={() => deleteHandler()}
          >
            Delete
          </button>
        </div>
      </div>
      <div>
        <>
          <div className="photos_list">
            <div className="photo_items">
              <figure>
                <ServerImage url={current_days_out?.image} />
              </figure>
            </div>
          </div>

          <div className="basic_info">
            <h4>Basic Info</h4>
            <div className="row">
              <div className="col-md-4">
                <div className="info_block">
                  <label>{STRINGS.NAME}</label>
                  <span>{Capitalize(current_days_out?.name)}</span>
                </div>
              </div>

              <div className="col-md-12">
                <div className="info_block">
                  <label>{STRINGS.WEBSITE}</label>
                  <p>
                    {current_days_out?.webLink
                      ? current_days_out?.webLink
                      : "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default withSnackbar(Days_Out_Details);
