import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDaysOut, getDaysOutDetails, getStayEatDetails, getStayHome, sortDaysOut } from "Redux/Actions/commonCRUD";
import { ICONS, ROUTE_CONSTANTS, titleCase } from "Shared";
import { useHistory } from "react-router";
import "./style.scss";
import ReactPaginate from "react-paginate";
import ServerImage from "Components/atoms/Server_Image";
import SingleSelectDropdown from "Components/atoms/SingleSelectDropdown";
import { all } from "@redux-saga/core/effects";
import { withSnackbar } from "notistack";
import { ControlPointSharp } from "@material-ui/icons";

function Days_Out_Home(props) {

    const dispatch = useDispatch();
    const all_days_Out = useSelector((state) => state?.daysOut?.daysOut);
    const [daysOut, setdaysOut] = useState(all_days_Out);
    const history = useHistory();
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [items, setItems] = useState(all_days_Out);
    const [currentItems, setCurrentItems] = useState(items);
    const itemsPerPage = 8;
    const sortingOptions=[
      {value:3,label:"Recently added"},
      {value:1,label:"Alphabetically (A-Z)"},
      {value:2,label:"Alphabetically (Z-A)"}
    ]
   
  
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const clickHandler=(id)=>{
    dispatch(getDaysOutDetails(id));
    history.push(ROUTE_CONSTANTS.DAYS_OUT_INFO.slice(0, -3) + id);
  }
  
  const [defaultSortValue,setDefaultSortValue]=useState([]);
  const sortHandler=(val)=>{
    let data={
      data: val?.value
    }
  
    dispatch(sortDaysOut(data,(message, type) => {
     props.enqueueSnackbar(message, {
       variant: type,
       autoHideDuration: 2000,
       anchorOrigin: {
         vertical: "top",
         horizontal: "center",
       },
     });
     if(type=="success"){
       dispatch(getDaysOut())
     }
   }))
 }

  useEffect(() => {
    setdaysOut(all_days_Out);
    setItems(all_days_Out);
    setCurrentItems(items);
    setDefaultSortValue(sortingOptions?.filter((value)=>value?.value===all_days_Out[0]?.daysOutSort))
  }, [all_days_Out]);
 

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);
  
  useEffect(()=>{
    dispatch(getDaysOut());
  },[])

  
  return (
    <>
      <>
        <div className="d-flex justify-content-between flex-wrap w-100 page-title">
          <h4 className="h4">Days Out</h4>

          <div className="col-md-4">
          <div className="form-group">
          <label>Sort by  </label>
          <SingleSelectDropdown
            id={defaultSortValue[0]?.value}
            options={sortingOptions}
            defaultValue={defaultSortValue[0]}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            changeHandler={(e) => {
              sortHandler(e)
            }}
          />
          {/* <label>This sorting would reflect on App side</label> */}
        </div>
        </div>

          <div className="col_rht">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => history.push(ROUTE_CONSTANTS.ADMIN_ADD_DAYS_OUT)}
            >
              Add Days Out
            </button>
          </div>
        </div>

        <div className="row trail_list">
          {currentItems?.map((value, index) => (
            <div
              className="col-xl-3 col-md-4 col-sm-6"
              onClick={() => clickHandler(value._id)}
            >
              <div className="trail_items">
                <figure>
                  <ServerImage
                    url ={value.image}
                    width="230px"
                    height="200px"
                    className="img-fluid"
                  />
                </figure>
                <div className="trail_summary">
                  <h6>{titleCase(value.name)}</h6>
                  <span className="trail_address">
                    {value.address}
                  </span>
                </div>
                {/* <div className="buttons">
              <button className="delete_button">D</button>
              <button className="edit_button">E</button>
            </div> */}
              </div>
            </div>
          ))}
        </div>
        

        <ReactPaginate
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    </>
  );
}

export default withSnackbar(Days_Out_Home);
