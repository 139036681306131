import { GET_COORDINATES, SET_COORDINATES, SET_GPXFILE_NAME } from "Redux/Actions/ActionType";

const initialState = {
  fileCoordinatesReducer: [],
  gpxFileName: "",
};

function fileCoordinateReducer(state = initialState, action) {
  switch (action.type) {

    case SET_COORDINATES:
      return {
        ...state,
        fileCoordinatesReducer: action.data,
      };

    case SET_GPXFILE_NAME:
        return {
            ...state, 
            gpxFileName: action.data,
        }

    default:
      return state;
  }
}

export default fileCoordinateReducer;
