import File_Field from "Components/atoms/FileField";
import CustomModal from "Components/atoms/Modal";
import ServerImage from "Components/atoms/Server_Image";
import TextField from "Components/atoms/TextField";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteProfiles, getProfiles, postProfiles, updateProfiles } from "Redux/Actions/commonCRUD";
import { ICONS, IMAGES, STRINGS, titleCase } from "Shared";
import { ROUTE_CONSTANTS } from "Shared";
import { withSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

function Profile(props) {
  const [showEditModal, setModalView] = useState(false);
  const [error, setError] = useState(false);
  const [errorImg, setErrorImg] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);
  const [data, setData] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState({});
  const history = useHistory();
  const currentItems = [];
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.profiles.profiles);

  const toggleModalView = () => {
    setmodalOpen((modalOpen) => !modalOpen);
  };

  const modalHandler = () => {
    setModalView(false);
    toggleModalView();
  };

  const submitHandler = (data) => {
    // if (!data[STRINGS.PROFILE_NAME].length) {
    //   setError(true)
    //   return
    // }
    let values = {
      name: data[STRINGS.PROFILE_NAME],
      image: data[STRINGS.PROFILE_IMAGE],
    };

    // console.log(values.image,"values.image")
    if (values?.image)
    {dispatch(
      postProfiles(values, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          setModalView(true);
          toggleModalView();
          dispatch(getProfiles());
        }
      })
    )} else {
      setErrorImg(true)
    };
  };

  const deleteHandler = (id) => {
    dispatch(
      deleteProfiles(id, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          dispatch(getProfiles());
        }
      })
    );
  };

  const toggleEditModalView = (data) => {
    setEditModalOpen((editModalOpen) => !editModalOpen);
    setEditModalData(data);
  };
  const imageHandler = () => {
    setData(null);
  };
  const editSubmitHandler = (data) => {
    let formData = {
      id: editModalData?._id,
      name: data.Name,
      image: data.Equipment,
    };

    dispatch(
      updateProfiles(formData, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          setModalView(true);
          toggleEditModalView();
          dispatch(getProfiles());
          setEditModalData({});
        }
      })
    );
  };

  useEffect(() => {
    dispatch(getProfiles());
  }, []);

  const validationSchema = Yup.object({
    [STRINGS.PROFILE_NAME]: Yup.string()
    .min(2, "Minimum Length is 2")
    .required("This is a required field")
      .max(50, "Maximum Length is 50"),
    [STRINGS.PROFILE_IMAGE]:Yup.string().required("This is a required field")
  });

  const validationSchema2 = Yup.object({
    [STRINGS.NAME]: Yup.string()
      .min(2, "Minimum Length is 2")
      .required("This is a required field")
      .max(50, "Maximum Length is 50"),
    // [STRINGS.PROFILE_IMAGE]:Yup.string().required("This is a required field")
  });

  return (
    <>
      <CustomModal isOpen={modalOpen} handleToggle={toggleModalView}>
        <Formik onSubmit={submitHandler} initialValues={{
          [STRINGS.PROFILE_NAME]: STRINGS.EMPTY_STRING,
          [STRINGS.PROFILE_IMAGE]:STRINGS.EMPTY_STRING
        }} validationSchema={validationSchema}>
          {(props) => (
            <Form>
              <div className="modal-header justify-content-center">
                <h3 className="h4">Add New Profile</h3>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    {/* <label>Name</label> */}
                    <div className="form-group">
                      <TextField
                        label={STRINGS.NAME}
                        name={STRINGS.PROFILE_NAME}
                        type="text"
                        className="form-control"
                        // onChange={()=>setError(false)}
                      />
                      {error ? <span className="error">This is a required field</span> : null}
                    </div>
                    <label>Image</label>
                    <File_Field
                      name={STRINGS.PROFILE_IMAGE}
                      setFieldValue={props.setFieldValue}
                      id="trail"
                      showmap={false}
                      fileName="Profile"
                      
                    />
                    {error ? <span className="error">This is a required field</span> : null}
                  </div>
                </div>
              </div>

              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-md btn-cancel" onClick={() => toggleModalView()}>
                  Cancel
                </button>
                <button className="btn btn-md btn-primary" type="submit">
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModal>

      <CustomModal isOpen={editModalOpen} handleToggle={toggleEditModalView}>
        <Formik
          onSubmit={editSubmitHandler}
          initialValues={{
            [STRINGS.NAME]: editModalData?.name,
            [STRINGS.EQUIPMENT]: editModalData?.image,
          }}
          validationSchema={validationSchema2}
        >
          {(props) => (
            <Form>
              <div className="modal-header justify-content-center">
                <h3 className="h4">Edit Profile</h3>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    {/* <label>Name</label> */}
                    <div className="form-group">
                      <TextField
                        label="Name"
                        name={STRINGS.NAME}
                        // setFieldValue={editModalData?.name}
                        type="text"
                        className="form-control"
                        
                      />
                      {error ? <span className="error">This is a required field</span> : null}
                    </div>
                    <label>Image</label>
                    <File_Field
                      name={STRINGS.EQUIPMENT}
                      setFieldValue={props.setFieldValue}
                      id="trail"
                      showmap={false}
                      showImage={false}
                      ImageUrl={editModalData?.image}
                      clickHandler={imageHandler}
                      showmapIcon={false}
                      fileName="Trail"
                    />
                  </div>
                </div>
              </div>

              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-md btn-cancel" onClick={() => toggleEditModalView()}>
                  Cancel
                </button>
                <button className="btn btn-md btn-primary" type="submit">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModal>

      <>
        <div className="d-flex justify-content-between flex-wrap w-100 page-title">
          <h4 className="h4">Profile</h4>

          <div className="col_rht">
            <button className="btn btn-sm btn-primary" onClick={() => modalHandler()}>
              Add Profile
            </button>
          </div>
        </div>

        <div className="row trail_list">
          {profiles?.map((value, index) => (
            <div
              className="col-xl-3 col-md-4 col-sm-6"
              //   onClick={() => clickHandler(value._id)}
            >
              <div className="trail_items">
                <figure>
                  <ServerImage url={value.image} width="230px" height="200px" className="img-fluid" />
                </figure>
                <div className="trail_summary">
                  <h6>{titleCase(value.name)}</h6>
                </div>
                <div class="delete-pic">
                  <button class="btn" onClick={() => deleteHandler(value._id)}>
                    <img src={ICONS.RemoveIcon} alt="Remove Icon" />
                  </button>
                </div>
                <div class="edit-pic">
                  <button class="btn" onClick={() => toggleEditModalView(value)}>
                    <img src={IMAGES.EDIT_BUTTON} alt="Edit Icon" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    </>
  );
}

export default withSnackbar(Profile);
