import { useHistory } from "react-router-dom";
import { ROUTE_CONSTANTS, titleCase } from "Shared";
import { IMAGES } from "Shared";
import ServerImage from "../Server_Image";

const Table = ({
    name = "",
    tagIndex,
    isSelected = false,
    tag_selected_style = {},
    tag_unselected_style = {},
    onClickDeleteHandler = () => { },
    onClickViewHandler = () => { },
    is_deletable = false,
    suitability = "",
    data = {},
    imageUrl = "",
    toggleEditModalView = () => { }
}) => {
    let can_delete = is_deletable;
    const history=useHistory();
    return (
        <>
            <table className="table table-success table-striped">
                <thead>
                    <tr key={1000 + 1}>
                        <th scope="col">S.No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Activity</th>
                        <th scope="col"> <center>Action</center></th>
                    </tr>
                </thead>
                <tbody>

                    {data.length ? data.map((value, index) => (
                        <tr key={10000 + index}>
                            <th scope="row">{index + 1}</th>
                            <td>{value?.user?.firstName + " " + value?.user?.lastName}</td>
                            <td>{value?.activity ? value?.activity?.map((item) => item) : "    --"}</td>
                            <td><center><div className="col_rht">

                                <button
                                    className="btn btn-sm btn-primary"
                                 onClick={() => onClickViewHandler(index,value)}
                                >
                                    View
                                </button>
                               {" "}
                                <button
                                    className="btn btn-sm btn-cancel"
                                 onClick={() => onClickDeleteHandler(value._id)}
                                >
                                    Delete
                                </button>
                            </div></center></td>
                        </tr>
                    )) : null}

                </tbody>
            </table>
        </>
    );
};

export default Table;
