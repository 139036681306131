import { takeLatest, all,put } from "redux-saga/effects";
import { LOGIN, LOGOUT, setAuthToken, setUserId } from "../Actions/Auth";
import { login, logout } from "Services/Api/Auth";
import requestSaga from "Shared/RequestSaga";
import { BASE_URL, postRequest, updateAuthToken } from "Shared";
import { RESEND_OTP, RESET_PASSWORD, SEND_OTP, VERIFY_OTP } from "Redux/Actions/ActionType";
import { startLoader, stopLoader } from "Redux/Actions/commonCRUD";
import { yellow } from "@mui/material/colors";

function* loginAuth({payload,callback}) {
  
  try {
    yield put(startLoader());
    const response = yield postRequest({
      API: `${BASE_URL}/v1/admin/login`,
      DATA: payload,
    });
    if(response.status===400){
      callback(response.data.msg, "error");
      yield put(stopLoader());
    }
    else if(response.status===401){
      callback(response.data.msg, "error");
      yield put(stopLoader());

    }

    else if(response.status===200){
      yield put(stopLoader());
      callback(response.data.msg, "success");
      updateAuthToken(response.data.token);
      yield put(setAuthToken(response.data.token))
    }
  } catch (error) {
    console.log(error);
  }
}

function* sendOtp({data,callback}) {
 
  try {
    yield put(startLoader());

    const response = yield postRequest({
      API: "{BASE_URL}/v1/admin/forgotPassword",
      DATA: data,
    });
    if(response.status===400){
      callback(response.data.msg, "error");
    }
    else if(response.status===401){
      callback(response.data.msg, "error");
    }
    else if(response.status===200){
      callback(response.data.msg, "success");
    }

  } catch (error) {
    console.log(error);
  }
  finally{
    yield put(stopLoader())
  }
}

function* verifyOtp({data,callback}) {
 
  try {
    yield put(startLoader())
    const response = yield postRequest({
      API: `${BASE_URL}/v1/admin/verifyOtp`,
      DATA: data,
    });
    if(response.status===400){
      callback(response.data.msg, "error");
    }
    else if(response.status===401){
      callback(response.data.msg, "error");
    }
    else if(response.status===200){
      yield put(setUserId(response.data.user._id))
      callback(response.data.msg, "success");
    }

  } catch (error) {
    console.log(error);
  }
  finally{
    yield put(stopLoader());
  }
}

function* resendOtp({data,callback}) {
 
  try {
   yield put(startLoader())
    const response = yield postRequest({
      API: "{BASE_URL}/v1/admin/resendCode",
      DATA: data,
    });
    
    if(response.status===400){
      callback(response.data.msg, "error");
    }
    else if(response.status===401){
      callback(response.data.msg, "error");
    }
    else if(response.status===200){
      callback("Sent Successfully", "success");
    }

  } catch (error) {
    console.log(error);
  }
  finally{
    yield put(stopLoader());
  }
}


function* resetPassword({data,callback}) {

 let userId = data.userId;
 delete data.userId;
  try {
    yield put(startLoader())
    const response = yield postRequest({
      API: `{BASE_URL}/v1/admin/resetPassword?userId=${userId}`,
      DATA: data,
    });
   
    if(response.status===400){
      callback(response.data.msg, "error");
    }
    else if(response.status===401){
      callback(response.data.msg, "error");
    }
    else if(response.status===200){
      callback(response.data.msg, "success");
    }

  } catch (error) {
    console.log(error);
  }
  finally{
    yield put(stopLoader())
  }
}



function* watchAuth() {
  yield all([
    takeLatest(LOGIN,loginAuth),
    takeLatest(SEND_OTP,sendOtp),
    takeLatest(VERIFY_OTP,verifyOtp),
    takeLatest(RESET_PASSWORD,resetPassword),
    takeLatest(RESEND_OTP,resendOtp)
    // takeLatest(LOGOUT, requestSaga, logout),
  ]);
}

export default watchAuth;
