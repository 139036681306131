import Login_Form from "./form";


const Admin_Login_Screen = () => {
  return (
    <>
      <Login_Form/>    
    </>
  )
};

export default Admin_Login_Screen;
