import trailReducer from "Redux/Reducers/trailReducer";

export const BASE_URL = "https://api.visitballyhoura.com";
// export const BASE_URL = "https://0d8c-112-196-113-2.ngrok.io"
// export const BASE_URL = "https://stageapi.visitballyhoura.com"
// export const BASE_URL= "https://6ce1-112-196-113-2.ngrok.io";

export const STRINGS = {
  PROFILES:"Profiles",
  TRAIL: "Trail",
  SOMETHING_WENT_WRONG: "Sorry, something went wrong.",
  OFFLINE_MESSAGE: "You appear to be offline. Please check your internet connection.",
  EMPTY_STRING:"",
  LOGIN:"Login",
  EMAIL:"Email",
  NAME:"Name",
  PASSWORD:"Password",
  EMAIL_ERROR:"Email is not valid",
  PASSWORD_ERROR:"Password is not valid",
  FORGOT_PASSWORD:"Forgot Password ",
  EMAIL_EMPTY:"Email is required",
  PASSWORD_EMPTY:"Password is required",
  PASSWORD_MIN_LENGTH:"Password must be 8 characters long",
  CONFIRM_PASSWORD:"ConfirmPassword",
  CONFIRM_PASSWORD_DOESNOT_MATCH:"Passwords doesn't match",
  EMPTY_FIELD:"This field is required",
  RESET_PASSWORD_INSTRUCTIONS:"Please enter the email and we'll send an email to your registered email address.",
  PASSWORD_CONSTRAINTS: "Password should contain min. 1 uppercase, 1 lowercase, 1 digit and 1 special character",
  RESET_PASSWORD:"Reset Password",
  SEND_EMAIL: "Send Email",
  TRAIL_NAME:"Trail Name",
  ADDRESS:"Address",
  DISTANCE:"Distance",
  TRAIL_START_TIME:"Trail start time",
  TRAIL_END_TIME:"Trail end time",
  DESCRIPTION:"Description",
  TRAIL_LENGTH:"Trail Length",
  TRAIL_ELEVATION:"Trail Elevation",
  TRAIL_CATEGORY:"Trail_Category",
  TRAIL_CATEGORY_TYPE:"Trail Category Type",
  TRAIL_SUITABILITY:"Trail Suitability",
  TRAIL_DIFFICULTY_LEVEL:"Trail Difficulty level",
  TRAIL_DISTANCE:"Trail Distance/Time",
  TRAIL_START_POINT:"Trail Start Point",
  MAXIMUM_TIME:"Maximum Time",
  MINIMUM_TIME:"Minimum Time",
  TRACKING_INFO:"Tracking Info",
  NEAREST_TOWN:"Nearest Town",
  TRAIL_TYPE:"Trail Type",
  EQUIPMENT:"Equipment",
  DIFFICULTY_LEVEL:"Difficulty Level",
  TRAIL_NAME:"Trail Name",
  TRAIL_CITY:"City",
  TRAIL_DESCRIPTION:"Description",
  TRAIL_COUNTRY:"Country",
  TRAIL_ADDRESS:"Address",
  TRAIL_CATEGORY:"",
  STATE:"State",
  WEBSITE:"Website",
  NORTH_WEST: "North West",
  SOUTH_EAST: "South East",
  PHONE_NUMBER:"Phone Number",
  TRAIL_IMAGE:"Trail Image",
  OTP:"OTP",
  PHONE_ERROR: "Please Enter valid phone number",
  CONTACT_NUMBER :"Contact Number",
  FACILITIES:"Facilities",
  FACILITIES_IMAGE:"Facilities Image",
  FACILITIES_DISTANCE:"Facilities_Distance",
  FACILITIES_FROM_DISTANCE:"Facilities_From Distance",
  FACILITIES_OPEN_TIME:"Open Time",
  FACILITIES_CLOSE_TIME:"Close Time",
  IMAGE_URL:"Image Url",
  OPEN_TIME:"openTime",
  CLOSE_TIME:"closeTime",
  PROFILE_NAME:"Profile_Name",
  PROFILE_IMAGE:"Profile_Image",
  STAY_EAT_IMAGE: "Stay or Eat Image",
  DAY_OUT_IMAGE: "Days Out Image",
  ATTRACTIONS_NEARBY_IMAGE: "Attractions Nearby Image",
  FACILITIES_DROPDOWN: "Facilitied Dropdown",
  OTHER_FACILITIES:"Other Facilities",
  TERRAIN_TYPE:"Terrain Type",
  BIKE_REPAIR:"Bike Repair",
  LATITUDE:"Latitude",
  LONGITUDE: "Longitude",
  WAYMARKING : "Waymarking",
  ELEVATION_GAIN:"Elevation Gain",
  AVERAGE_SPEED:"Average Speed",
  TIME:"Time",
  MOVINGTIME:"Moving Time",
  CALORIES:"Calories",
  COMPLETED_AT:"Completed on"
};

export const Form_Strings={
  NAME:"Name",
  DESCRIPTION:"Description",
  TRAIL:"Trail",
  IMAGE:"Image",
  ADDRESS:"Address",
  WEBSITE:"Website",
  EMAIL:"Email",
  MAXIMUM_TIME:"Maximum Time",
  MINIMUM_TIME:"Minimum Time",
  NEAREST_TOWN:"Nearest Town",
  TRAIL_TYPE:"Trail_Type",
  TRAIL_SUITABILITY:"Trail_Suitability",
  TRAIL_EQUIPMENTS:"Trail_Equipment",
  EQUIPMENT:"Equipments",
  DIFFICULTY_LEVEL:"Difficulty_Level",
  TRAIL_NAME:"Trail Name",
  TRAIL_CITY:"City",
  TRAIL_LENGTH:"Trail Length",
  TRAIL_ELEVATION:"Trail Elevation",
  TRAIL_DESCRIPTION:"Description",
  TRAIL_COUNTRY:"Country",
  TRAIL_ADDRESS:"Address",
  TRAIL_CATEGORY:"Trail_Category",
  TRAIL_START_POINT:"Trail Start Point",
  TRAIL_STATE:"State",
  TRAIL_IMAGE:"Image",
  PHONE_NUMBER:"Phone Number",
  OTP:"OTP",
  IMAGE :"Trail Image",
  EQUIPMENTS : "Equipments",
  TRAIL_IMAGE: "trailImage",
  NORTH_WEST: "North West",
  SOUTH_EAST: "South East"
}

export const LABELS={
  LOGIN:"Login",
  EMAIL:"Email",
  PASSWORD:"Password",
  FORGOT_PASSWORD:"Forgot Password",
  SEND_EMAIL:"Send Email",
  NEW_PASSWORD:"New Password",
  CONFIRM_PASSWORD:"Confirm Password",
  RESET_PASSWORD:"Reset Password",
  TRAIL_NAME:"Trail Name",
  COUNTRY:"Country",
  STATE:"State",
  CITY:"City",
  ADDRESS:"Address",
  DISTANCE:"Distance",
  TRAIL_START_TIME:"Trail start time",
  TRAIL_END_TIME:"Trail end time",
  SELECT:"Select",
  DESCRIPTION:"Description",
  TRAIL_LENGTH:"Trail Length",
  NORTH_WEST: "North West",
  SOUTH_EAST: "South East",
  TRAIL_ELEVATION:"Trail Elevation",
  TRAIL_START_POINT:"Trail Start Point",
  MAXIMUM_TIME:"Maximum Time (in Hrs)",
  MINIMUM_TIME:"Minimum Time (in Hrs)",
  TRACKING_INFO:"Tracking Info",
  NEAREST_TOWN:"Neares Town",
  TRAIL_TYPE:"Trail Type",
  EQUIPMENT:"Equipment",
  STATE:"State",
  NAME:"Name",
  WEBSITE:"Website",
  PHONE_NUMBER:"Phone Number",
  OTP:"OTP",
  FROM_DISTANCE:"From Distance",

}

export const REGEX = {
  EMAIL: /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,/:;<=>?@[\]^_`{|}~÷°¬±µ‰¤ƒ¥€£¢ß¿¡©®™§†‡—¶])(?=.{8,})/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{1,6}$/,
} 

export const PLACEHOLDERS={
  EMAIL:"Email",
  PASSWORD:"Password",
  CONFIRM_PASSWORD:"Confirm Password",
  TRAIL_NAME:"Trail Name",
  COUNTRY:"Country",
  STATE:"State",
  CITY:"City",
  ADDRESS:"Address",
  DISTANCE:"Distance",
  TRAIL_START_TIME:"Trail start time",
  TRAIL_END_TIME:"Trail end time",
  SELECT:"Select",
  DESCRIPTION:"Description",
  TRAIL_LENGTH:"Trail Length",
  TRAIL_ELEVATION:"Trail Elevation",
  WRITE_HERE:"Write here....",
  TRAIL_START_POINT:"Trail Start Point",
  MAXIMUM_TIME:"HH:MM",
  MINIMUM_TIME:"HH:MM",
  TRACKING_INFO:"Tracking Info",
  NEAREST_TOWN:"Neares Town",
  TRAIL_TYPE:"Trail Type",
  EQUIPMENT:"Equipment",
  NAME:"Name",
  NORTH_WEST: "North-West Coordinates",
  SOUTH_EAST: "South-East Coordinates"
}

// export const DIFFICULTY_LEVEL=[
// {value: 1, label:"Easy"},
// {value: 2, label:"Medium"},
// {value: 3, label:"Difficult"},
// ]

// export const TRAIL_CATEGORY=[
//   { value: "1", label: "Adventurous" },
//   { value: "2", label: "Nostalgic" },
//   { value: "3", label: "Active" },
//   { value: "4", label: "Creative" },
//   { value: "5", label: "Curious" },
//   { value: "6", label: "Calm" },
// ];

// export const TRAIL_SUITABILITY=[
//   { value: "1", label: "Partially Paved" },
//   { value: "2", label: "Family Friendly" },
//   { value: "3", label: "Kid Friendly" },
//   { value: "4", label: "Wheelchair Friendly" },
//   { value: "5", label: "Pet Friendly" },
// ]

