import File_Field from "Components/atoms/FileField";
import TextField from "Components/atoms/TextField";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getProfiles, postDaysOut } from "Redux/Actions/commonCRUD";
import { LABELS, PLACEHOLDERS, REGEX, ROUTE_CONSTANTS, STRINGS } from "Shared";
import { withSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Multi_Select_Dropdown from "Components/atoms/Multi_Select_Dropdown";
import { useEffect, useState } from "react";

function Days_Out(props) {
  const dispatch = useDispatch();
  const history=useHistory();
  const [profiles, setProfiles] = useState([]);
  const profileType = useSelector((state) => state?.profiles?.profiles);
  const submitHandler = (val) => {
    let data = {
      name: val.Name,
      image: val[STRINGS.DAY_OUT_IMAGE],
      webLink: val.Website,
      profileType : profiles.map((value)=>value.value),
    };
    dispatch(postDaysOut(data,(message, type) => {
      props.enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      })
      if(type=="success"){
        history.push(ROUTE_CONSTANTS.DAYS_OUT_HOME)
      }
    }));
  };

  const current_Profile_Type = profileType.map((value) =>
  Object.assign(
    {},
    {
      label: (
        <div>
          <img
            src={`https://${value?.imageUrl}`}
            height="30px"
            width="30px"
            style={{ borderRadius: "50%" }}
          />
          &nbsp;&nbsp;{value?.name}
        </div>
      ),
      value: value?._id,
    }
  )
);

  const validationSchema=Yup.object({
    [STRINGS.NAME]: Yup.string().min(2, "Minimum Length is 2").required(STRINGS.EMPTY_FIELD).max(25, "Maximum Length is 25"),
    [STRINGS.DAY_OUT_IMAGE]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.WEBSITE]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.PROFILES]:Yup.array()
    .min(1, STRINGS.EMPTY_FIELD)
    .required(STRINGS.EMPTY_FIELD)
  })

  useEffect(()=>{
    dispatch(getProfiles())
  },[])

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h4 className="h4">Add Daysout</h4>
      </div>
      <div>
        <Formik onSubmit={submitHandler} initialValues={{
          [STRINGS.DAY_OUT_IMAGE]: STRINGS.EMPTY_STRING,
          [STRINGS.NAME]: STRINGS.EMPTY_STRING,
          [STRINGS.WEBSITE]: STRINGS.EMPTY_STRING,
          [STRINGS.PROFILES]: STRINGS.EMPTY_STRING
        }} validationSchema={validationSchema}>
          {(props) => (
            <Form>
              <div className="form-group">
                <label>Image</label>
                <File_Field
                  name={STRINGS.DAY_OUT_IMAGE}
                  placeholder=""
                  label="Upload Image"
                  setFieldValue={props.setFieldValue}
                  id="daysout"
                  fileName="Hotel"
                />
              </div>
              <div className="form-group">
                <TextField
                  label={LABELS.NAME}
                  placeholder={PLACEHOLDERS.WRITE_HERE}
                  name={STRINGS.NAME}
                  type="text"
                />
              </div>
             
                    <div className="form-group">
                      <label>Profile Type</label>
                      <Multi_Select_Dropdown
                        name={STRINGS.PROFILES}
                        options={current_Profile_Type}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        changeHandler={(e) => {
                          setProfiles(e);
                          props.setFieldValue(STRINGS.PROFILES, e);
                        }}
                      />
                    </div>
                  
              <div className="form-group">
              <TextField
                label={LABELS.WEBSITE}
                placeholder={PLACEHOLDERS.WRITE_HERE}
                name={STRINGS.WEBSITE}
                type="text"
              />
              </div>
              <button type="submit" className="btn btn-md btn-primary">Submit</button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default withSnackbar(Days_Out);
