import Forgot_Password_Form from "./form";

const Forgot_Password_Screen = () => {
  return(
    <>
    <Forgot_Password_Form/>
    </>
  )
};

export default Forgot_Password_Screen;