export const UTILITIES = {};

export const titleCase = (string) => {

    if (string) return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

export const Capitalize = (input) => {
    if (input) {
        var words = input?.split(' ');
        var CapitalizedWords = [];
        words?.forEach(element => {
            if (element)
                CapitalizedWords?.push(element[0]?.toUpperCase() + element?.slice(1, element?.length));
        });
        return CapitalizedWords.join(' ');
    }
}

export const ColourOptions = [
    {
        label: "Green",
        value: "#024638"
    }
    ,
    {
        label: "White",
        value: "#FFFFFF"
    }
    ,
    {
        label: "Red",
        value: "#e23b3b"
    }
    , {
        label: "Blue",
        value: "#00A5BA"
    }
    ,
    {
        label: "Black",
        value: "#000000"
    }
    ,
    {
        label: "Purple",
        value: "#4E398F"
    },
    {
        label: "Orange",
        value: "#FF8C00"
    }
    , {
        label: "Brown",
        value: "#964B00"
    }
    ,
    {
        label: "Pink",
        value: "#283bf4"
    }
    ,
    {
        label: "Yellow",
        value: "#283bf4"
    }
]




// import { MapContainer } from "react-leaflet";
// import 'leaflet/dist/leaflet.css'
// import { TileLayer } from "react-leaflet";
// import { CircleMarker } from "react-leaflet";
// import { Polyline } from "react-leaflet";
// import "./style.css"
// import { positions } from "Shared";


// const GpxMap = () => {
//   return (
//     <div >
//       <MapContainer center={positions[0]} zoom={12} scrollWheelZoom={true}>
//         <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
//         <CircleMarker
//           center={positions[0]}
//           position={positions[0]}
//           radius={10}
//           pathOptions={{ color: "black" }}
//         />
//         <CircleMarker
//           center={positions[0]}
//           position={positions[1332]}
//           radius={20}
//           pathOptions={{ color: "green" }}
//         />
//         <Polyline
//           pathOptions={{ fillColor: "red", color: "blue" }}
//           positions={[positions]}
//         />
//       </MapContainer>
//     </div>
//   );
// };

// export default GpxMap;

