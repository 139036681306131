import * as Yup from "yup";
import TextField from "Components/atoms/TextField";
import {
  STRINGS,
  Form_Strings,
  LABELS,
  PLACEHOLDERS,
  DIFFICULTY_LEVEL,
  TRAIL_CATEGORY,
  TRAIL_SUITABILITY,
} from "Shared/Constants";
import { Form, Formik, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { ROUTE_CONSTANTS } from "Shared/Routes";
import Dropdown from "Components/atoms/Dropdown";
import { Country, State, City } from "country-state-city";
import { useDebugValue, useEffect, useRef, useState } from "react";
import Tag from "Components/atoms/Tag";
import { act } from "react-dom/test-utils";
import Multi_Select_Dropdown from "Components/atoms/Multi_Select_Dropdown";
import File_Field from "Components/atoms/FileField";
import { useDispatch, useSelector } from "react-redux";
import filterSagaWatcher from "Redux/Sagas/filterCRUD";
import { getCategories, getFilter } from "Redux/Actions/filterCRUD";
import {
  addtrail,
  getActivity,
  getAllOtherFacilities,
  getEquipments,
  getOtherFacilities,
  getProfiles,
  gettrails,
  setLocation,
  uploadfile,
} from "Redux/Actions/commonCRUD";
import Reset_Password_Form from "Views/Authentication/ResetPassword/form";
import GpxMap from "Components/atoms/Map";
import "./style.scss";
import Card from "Components/atoms/Cards";
import { setAuthToken } from "Redux/Actions/Auth";
import { ColourOptions, IMAGES, updateAuthToken } from "Shared";
import { withSnackbar } from "notistack";
import ImageDropdown from "Components/atoms/ImageDropdown";
import CustomModal from "Components/atoms/Modal";
import {
  ContactSupportOutlined,
  LocalConvenienceStoreOutlined,
} from "@material-ui/icons";
import MultipleImageUploader from "Components/atoms/MultipleImageUploader";
import MultipleFileUploader from "Components/atoms/MultipleFileUploader";
import TextArea from "Components/atoms/TextArea";

function Add_Trail(props) {
  let northEast1 = useRef(null);
  let southWest1 = useRef(null);
  const [northEast, setNorthEast] = useState("");
  const [southWest, setSouthWest] = useState("");
  const [publicTransport, setPublicTransport] = useState([]);
  const [repairPoints, setRepairPoints] = useState([]);
  const [garagePoints, setGaragePoints] = useState([]);
  const [emergencyNumbers, setEmergencyNumber] = useState([]);
  const [placesToStop, setPlacestoStop] = useState([]);
  const [modalOpen, setmodalOpen] = useState(false);
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [distanceModal, setDistanceModal] = useState(false);
  const [error, setError] = useState(false);
  const [showEditModal, setModalView] = useState(false);
  const dispatch = useDispatch();
  const coordinates = useSelector((state) => state?.location?.coordinates);
  const gpxfilename = useSelector((state) => state?.addGPXfileCoordinate?.gpxFileName)
  // const gpxfilename = useSelector((state) => state?.current_trail?.current_trail?.locationUrl)
  
  // console.log(gpxfilename,"gpxfilename")
  const [finalNearbyAttractions, setFinalNearbyAttractions] = useState([]);
  const [finalPublicTransport, setFinalPublicTransport] = useState([]);
  const [finalBikeRepairPoints, setFinalBikeRepairPoints] = useState([]);
  const [finalGaragePoints, setFinalGaragePoints] = useState([]);
  const [finalEmergencyNumbers, setFinalEmergencyNumbers] = useState([]);
  const trail_activities = useSelector((state) => state.activity.activity);

  var filters = useSelector((state) => state.filter.filters) || [];
  var categories = useSelector((state) => state.category.category) || [];
  const all_Equipments = useSelector((state) => state.equipments.equipments);
  const history = useHistory();
  const profileType = useSelector((state) => state?.profiles?.profiles);
  const [profiles, setProfiles] = useState([]);
  const [bikeRepairPoints, setBikeRepairPoints] = useState([]);
  const [nearbyAttractionspoints, setNearbyAttractionsPoints] = useState([]);
  const [publicTransportPoints, setPublicTransportPoints] = useState([]);

  const nearbyAttractionsData = useSelector(
    (state) => state.facilities.nearbyAttractions
  );
  const publicTransportData = useSelector(
    (state) => state.facilities.publicTransport
  );
  const garagesData = useSelector((state) => state.facilities.garages);
  const bikeRepairData = useSelector((state) => state.facilities.bikeRepair);
  const emergencyNumbersData = useSelector(
    (state) => state.facilities.emergencyNumbers
  );

  const toggleModalView = () => {
    setmodalOpen((modalOpen) => !modalOpen);
  };

  const toggleFileUploadModalView = () => {
    setFileUploadModal((value) => !value);
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    // NORTH_WEST: "North West",
    // SOUTH_EAST: "South East",
    if (name == "North West") {
      setNorthEast(value);
    } else if (name == "South East") {
      setSouthWest(value);
    }
  };

  let category = categories.map((data) =>
    Object.assign({}, { label: data.name, value: data._id })
  );
  let filter_suitability = filters
    .filter((value) => value.type === 1)
    .map((value) => value.filter)[0]
    ?.map((data) => Object.assign({}, { label: data.name, value: data._id }));

  let filter_trail = filters
    .filter((value) => value.type === 3)
    .map((value) => value.filter)[0]
    ?.map((data) => Object.assign({}, { label: data.name, value: data._id }));
  let filter_difficulty = filters
    .filter((value) => value.type === 4)
    ?.map((value) => value.filter)[0]
    ?.map((data) => Object.assign({}, { label: data.name, value: data._id }));

  const startPointCoordinates = useSelector(
    (state) => state?.location?.coordinates
  );
  const [dropdownNearbyAttractions, setDropdownNearbyAttractions] = useState(
    []
  );

  const dropDownPublicTransport = publicTransportData?.map((value) =>
    Object.assign(
      {},
      {
        label: (
          <div>
            <img
              src={`https://${value?.imageUrl}`}
              height="30px"
              width="30px"
              style={{ borderRadius: "15%" }}
            />
            &nbsp;&nbsp;{value?.name}
          </div>
        ),
        value: value,
      }
    )
  );

  const dropDownGarages = garagesData?.map((value) =>
    Object.assign(
      {},
      {
        label: (
          <div>
            <img
              src={`https://${value?.imageUrl}`}
              height="30px"
              width="30px"
              style={{ borderRadius: "15%" }}
            />
            &nbsp;&nbsp;{value?.name}
          </div>
        ),
        value: value,
      }
    )
  );

  const dropdownBikeRepair = bikeRepairData?.map((value) =>
    Object.assign(
      {},
      {
        label: (
          <div>
            <img
              src={`https://${value?.imageUrl}`}
              height="30px"
              width="30px"
              style={{ borderRadius: "15%" }}
            />
            &nbsp;&nbsp;{value?.name}
          </div>
        ),
        value: value,
        id: value._id,
      }
    )
  );

  let current_Equipments = all_Equipments?.map((value) =>
    Object.assign(
      {},
      {
        label: (
          <div>
            <img
              src={`https://${value?.image?.src}`}
              height="30px"
              width="30px"
              style={{ borderRadius: "15%" }}
            />
            &nbsp;&nbsp;{value?.text}
          </div>
        ),
        value: value?.key,
      }
    )
  );

  let currentColorOptions = ColourOptions?.map((value) =>
    Object.assign(
      {},
      {
        label: (
          <div>
            <img
              height="30px"
              width="30px"
              style={{ borderRadius: "15%", backgroundColor: value.value }}
            />
            &nbsp;&nbsp;{value?.label}
          </div>
        ),
        value: value,
      }
    )
  );
  let country = Country?.getAllCountries();

  let currentEmergencyNumbers = emergencyNumbersData.map((value) =>
    Object.assign({}, { label: value.name, value: value })
  );

  // console.log(data.Map, "2ASdata.map")
  let countries = country?.map((value) => value);
  const submithandler = (data) => {
    // console.log(gpxfilename[0], "ASdata.map")
    let formData = {
      trailName: data[Form_Strings.TRAIL_NAME],
      trailImage: data[STRINGS.TRAIL_IMAGE],
      trailAddress: data[Form_Strings.TRAIL_ADDRESS],
      trailDistance: data[Form_Strings.TRAIL_LENGTH],
      trailElevation: "" + data[Form_Strings.TRAIL_ELEVATION],
      trailDescription: data[Form_Strings.TRAIL_DESCRIPTION],
      difficultyLevel: data[Form_Strings.DIFFICULTY_LEVEL].value,
      activity: activity.map((value) => value.id),
      nearestTown: data[Form_Strings.NEAREST_TOWN],
      // northEast: northEast,
      // sourthWest: southWest,
      startPoint: {
        coordinates: [startPointCoordinates?.lng, startPointCoordinates?.lat],
        description: data[Form_Strings.TRAIL_START_POINT],
      },
      trailType: data[Form_Strings.TRAIL_TYPE]?.map((value) => value.value),
      equipments:
        data[Form_Strings.EQUIPMENT].map((value) => value.value) || [],
      suitability: data[Form_Strings.TRAIL_SUITABILITY].map(
        (value) => value.value
      ),
      categories: data[Form_Strings.TRAIL_CATEGORY]?.map(
        (value) => value.value
      ),
      minimumTime: parseInt(data[Form_Strings.MINIMUM_TIME]),
      maximumTime: parseInt(data[Form_Strings.MAXIMUM_TIME]),
      locationUrl: gpxfilename,
      placesToStop: finalNearbyAttractions,
      garages: finalGaragePoints,
      publicTransport: finalPublicTransport,
      emergencyNumbers: finalEmergencyNumbers,
      bikesRepair: finalBikeRepairPoints,
      profileType: profiles.map((value) => value?.value),
      terrainType: data[STRINGS.TERRAIN_TYPE],
      webLink: data[STRINGS.WEBSITE],
      color: {
        name: data[STRINGS.WAYMARKING]?.value?.label,
        hexCode: data[STRINGS.WAYMARKING]?.value?.value,
      },
    };

    dispatch(
      addtrail(formData, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        if (type == "success") {
          history.push(ROUTE_CONSTANTS.ADMIN_TRAILS);
        }
      })
    );
  };

  const [activities, setActivities] = useState([]);
  var activity = activities.filter((value) => value.isSelected === true);

  const [wayMarking, setWayMarking] = useState({});

  const tag_selected_style = {
    background: "#9dbf1b",
    color: "black",
    border: "1px solid #E9550C",
  };
  const tag_unselected_style = {
    background: "#EBEBEB",
    border: "1px solid #1F4B81",
    color: "black",
  };

  const emergencyNumbersHandler = (value) => {
    let data = {
      name: value.EmergencyNumber,
      contactNumber: value.Number,
    };

    setEmergencyNumber([...emergencyNumbers, data]);
  };

  let current_Profile_Type = profileType.map((value) =>
    Object.assign(
      {},
      {
        label: (
          <div>
            <img
              src={`https://${value?.imageUrl}`}
              height="30px"
              width="30px"
              style={{ borderRadius: "50%" }}
            />
            &nbsp;&nbsp;{value?.name}
          </div>
        ),
        value: value?._id,
      }
    )
  );

  useEffect(() => {
    dispatch(setLocation([]));
    dispatch(getFilter());
    dispatch(getCategories());
    dispatch(getEquipments());
    dispatch(getProfiles());
    dispatch(getActivity());
    dispatch(getAllOtherFacilities());

    return () => {
      dispatch(setLocation([]));
      document.getElementById("map").classList.remove("visible");
      document.getElementById("map_close_btn").classList.remove("visible");
    };
  }, []);

  useEffect(() => {
    if (filters.length) {
      setActivities(
        trail_activities.map((data) =>
          Object.assign(
            {},
            { id: data._id, name: data.name, isSelected: false }
          )
        )
      );
    }
  }, [trail_activities]);

  const toggleHandler = () => {
    toggleFileUploadModalView();
  };

  const changeHandler = (e) => {};
  const tag_clickHandler = (tagIndex) => {
    if (activities.length)
      setActivities((value) => {
        return value.map((data, index) =>
          tagIndex === index
            ? Object.assign({}, data, { isSelected: !data.isSelected })
            : data
        );
      });
  };

  const handleNW = (e) => {
    const { value } = e.target;
    setNorthEast(value);
  };

  const handleSE = (e) => {
    const { value } = e.target;
    setSouthWest(value);
  };

  const handleChange = (e) => {

    // const {value} = e.target;
    let coordinate = {
      latitude: e.lat(),
      longitude: e.lng(),
    };
    // setNorthEast(coordinate)
    northEast1.current = coordinate;
    setTimeout(() => {
      setNorthEast(northEast1.current);
    }, 2000);
  };



  const mapViewHandler = () => {
    document.getElementById("map").classList.add("visible");
    document.getElementById("map_close_btn").classList.add("visible");
    document.getElementById("map-sec").classList.add("visible");
  };

  useEffect(() => {
    if (nearbyAttractionspoints && nearbyAttractionspoints?.length) {
      setFinalNearbyAttractions(
        nearbyAttractionspoints.map((value) =>
          Object.assign(
            {},
            {
              _id: value.value?._id,
              name: value.value?.name,
              image: value.value?.image,
              webLink: value.value?.webLink,
              coordinates: value.value?.coordinates,
            }
          )
        )
      );
    }
  }, [nearbyAttractionspoints]);

  useEffect(() => {
    if (bikeRepairPoints && bikeRepairPoints?.length) {
      setFinalBikeRepairPoints(
        bikeRepairPoints.map((value) =>
          Object.assign(
            {},
            {
              _id: value.value?._id,
              name: value.value?.name,
              image: value.value?.image,
              webLink: value.value?.webLink,
              coordinates: value.value?.coordinates,
            }
          )
        )
      );
    }
  }, [bikeRepairPoints]);

  useEffect(() => {
    if (publicTransportPoints && publicTransportPoints?.length) {
      setFinalPublicTransport(
        publicTransportPoints.map((value) =>
          Object.assign(
            {},
            {
              _id: value.value?._id,
              name: value.value?.name,
              image: value.value?.image,
              webLink: value.value?.webLink,
              coordinates: value.value?.coordinates,
            }
          )
        )
      );
    }
  }, [publicTransportPoints]);

  useEffect(() => {
    if (garagePoints && garagePoints?.length) {
      setFinalGaragePoints(
        garagePoints.map((value) =>
          Object.assign(
            {},
            {
              _id: value.value?._id,
              name: value.value?.name,
              image: value.value?.image,
              webLink: value.value?.webLink,
              coordinates: value.value?.coordinates,
            }
          )
        )
      );
    }
  }, [garagePoints]);

  useEffect(() => {
    if (emergencyNumbers && emergencyNumbers?.length) {
      setFinalEmergencyNumbers(
        emergencyNumbers.map((value) =>
          Object.assign(
            {},
            {
              _id: value.value?._id,
              name: value.value?.name,
              contactNumber: value?.value?.contactNumber,
            }
          )
        )
      );
    }
  }, [emergencyNumbers]);

  useEffect(() => {
    setDropdownNearbyAttractions(
      nearbyAttractionsData?.map((value) =>
        Object.assign(
          {},
          {
            label: (
              <div>
                <img
                  src={`https://${value?.imageUrl}`}
                  height="30px"
                  width="30px"
                  style={{ borderRadius: "15%" }}
                />
                &nbsp;&nbsp;{value?.name}
              </div>
            ),
            value: value,
          }
        )
      )
    );
  }, [nearbyAttractionsData]);

  const validationSchema = Yup.object({
    [STRINGS.TRAIL_NAME]: Yup.string()
      .min(2, "Minimum Length is 2")
      .required("This is a required field")
      .max(50, "Maximum Length is 50"),
    [STRINGS.ADDRESS]: Yup.string()
      .min(2, "Minimum Length is 2")
      .required("This is a required field")
      .max(80, "Maximum Length is 80"),
    [STRINGS.DESCRIPTION]: Yup.string()
      .min(2, "Minimum Length is 2")
      .required("This is a required field"),
    [STRINGS.TRAIL_LENGTH]: Yup.number()
      .positive("Length can't be a negative value")
      .required("Trail Length is a required field")
      .max(99, "Trail length should be less than 100 Km")
      .positive(),
    [STRINGS.TRAIL_ELEVATION]: Yup.number()
      .positive("Elevation can't be a negative value")
      .required("Trail Elevation is a required field")
      .max(99, "Trail Elevation should be less than 100 Km")
      .positive(),
    [STRINGS.MINIMUM_TIME]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.MAXIMUM_TIME]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.TRAIL_IMAGE]: Yup.string().required(STRINGS.EMPTY_FIELD),
    [STRINGS.TERRAIN_TYPE]: Yup.string()
      .min(2, "Minimum Length is 2")
      .required("This is a required field")
      .max(100, "Maximum Length is 100"),
    [STRINGS.TRAIL_START_POINT]: Yup.string()
      .required(STRINGS.EMPTY_FIELD)
      .max(100, "Maximum 100 characters are allowed"),
    [STRINGS.NEAREST_TOWN]: Yup.string()
      .required(STRINGS.EMPTY_FIELD)
      .max(100, "Maximum 100 characters are allowed"),
    profiles: Yup.array()
      .min(1, STRINGS.EMPTY_FIELD)
      .required(STRINGS.EMPTY_FIELD),
    Equipments: Yup.array()
      .min(1, STRINGS.EMPTY_FIELD)
      .required(STRINGS.EMPTY_FIELD),
    Trail_Type: Yup.array()
      .min(1, STRINGS.EMPTY_FIELD)
      .required(STRINGS.EMPTY_FIELD),
    Trail_Suitability: Yup.array()
      .min(1, STRINGS.EMPTY_FIELD)
      .required(STRINGS.EMPTY_FIELD),
    Trail_Category: Yup.array()
      .min(1, STRINGS.EMPTY_FIELD)
      .required(STRINGS.EMPTY_FIELD),
    Difficulty_Level: Yup.object().required(STRINGS.EMPTY_FIELD).nullable(),
    [STRINGS.WEBSITE]: Yup.string().required(STRINGS.EMPTY_FIELD),
  });

  // console.log(["gpxFile"])
  // console.log("Testing Console..........")
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h4 className="h4">Add trails</h4>
      </div>

      <div className="form_sec">
        <Formik
          onSubmit={submithandler}
          initialValues={{
            [STRINGS.TRAIL_NAME]: STRINGS.EMPTY_STRING,
            [STRINGS.ADDRESS]: STRINGS.EMPTY_STRING,
            [STRINGS.DESCRIPTION]: STRINGS.EMPTY_STRING,
            [STRINGS.TRAIL_LENGTH]: STRINGS.EMPTY_STRING,
            [STRINGS.TRAIL_ELEVATION]: STRINGS.EMPTY_STRING,
            [STRINGS.MINIMUM_TIME]: STRINGS.EMPTY_STRING,
            [STRINGS.MAXIMUM_TIME]: STRINGS.EMPTY_STRING,
            [STRINGS.TRAIL_IMAGE]: STRINGS.EMPTY_STRING,
            [STRINGS.NORTH_WEST]: northEast,
            [STRINGS.SOUTH_EAST]: southWest,
            [STRINGS.TRAIL_START_POINT]: STRINGS.EMPTY_STRING,
            [STRINGS.NEAREST_TOWN]: STRINGS.EMPTY_STRING,
            [STRINGS.TERRAIN_TYPE]: STRINGS.EMPTY_STRING,
            [STRINGS.WEBSITE]: STRINGS.EMPTY_STRING,
            "gpxFile": gpxfilename || STRINGS.EMPTY_STRING,
            locationUrl: gpxfilename || STRINGS.EMPTY_STRING,
            profiles: STRINGS.EMPTY_STRING,
            Equipments: STRINGS.EMPTY_STRING,
            Trail_Type: STRINGS.EMPTY_STRING,
            Trail_Suitability: STRINGS.EMPTY_STRING,
            Trail_Category: STRINGS.EMPTY_STRING,
            Difficulty_Level: STRINGS.EMPTY_STRING,
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <CustomModal
                isOpen={fileUploadModal}
                handleToggle={toggleFileUploadModalView}
              >
                <div className="modal-header justify-content-center">
                  <h3 className="h4">Upload Gpx Files</h3>
                </div>

                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <MultipleFileUploader
                          name="gpxFile"
                          placeholder={"Please upload gpx file"}
                          label="Upload File"
                          setFieldValue={("Location", props.setFieldValue)}
                          id="location"
                          showmap={true}
                          add="add"
                          clickHandler={() => toggleHandler()}
                        />
                        {error ? (
                          <span className="error">
                            This is a required field
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </CustomModal>

              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Trail Image</label>
                    <File_Field
                      name={STRINGS.TRAIL_IMAGE}
                      placeholder=""
                      label="Upload Trail Image"
                      setFieldValue={props.setFieldValue}
                      id="trail"
                      showmap={false}
                      fileName="Trail"
                    />
                  </div>
                </div>
              </div>

              <fieldset>
                <legend>Trail Basic Info</legend>
                <div className="form-row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <TextField
                        label={LABELS.TRAIL_NAME}
                        placeholder={PLACEHOLDERS.TRAIL_NAME}
                        name={STRINGS.TRAIL_NAME}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="d-block">Upload Map</label>
                      <button
                        className="btn btn-md btn-cancel m-1"
                        type="button"
                        onClick={toggleFileUploadModalView}
                      >
                        Add Files
                      </button>
                      {coordinates && Object?.values(coordinates) ? (
                        <>
                          <button
                            className="btn btn-md btn-cancel m-1"
                            type="button"
                            onClick={() => {
                              mapViewHandler();
                            }}
                          >
                            View Map
                          </button>
                          
                        </>
                      ) : null}
                    </div>
                  </div>
                 
                  <div className="col-md-4">
                    <div className="form-group">
                      <TextField
                        label={PLACEHOLDERS.ADDRESS}
                        placeholder={PLACEHOLDERS.ADDRESS}
                        name={STRINGS.ADDRESS}
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <TextArea
                        label={LABELS.DESCRIPTION}
                        placeholder={PLACEHOLDERS.DESCRIPTION}
                        name={STRINGS.DESCRIPTION}
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <TextField
                        label={LABELS.TRAIL_LENGTH}
                        placeholder={PLACEHOLDERS.TRAIL_LENGTH}
                        name={STRINGS.TRAIL_LENGTH}
                        type="number"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <TextField
                        label={LABELS.TRAIL_ELEVATION}
                        placeholder={PLACEHOLDERS.TRAIL_ELEVATION}
                        name={STRINGS.TRAIL_ELEVATION}
                        type="number"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Profile Type</label>
                      <Multi_Select_Dropdown
                        name="profiles"
                        options={current_Profile_Type}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        changeHandler={(e) => {
                          setProfiles(e);
                          props.setFieldValue("profiles", e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <TextField
                        label={LABELS.WEBSITE}
                        placeholder={PLACEHOLDERS.WEBSITE}
                        name={STRINGS.WEBSITE}
                        type="text"
                        className="without_ampm"
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <TextField
                        label={LABELS.NORTH_WEST}
                        placeholder={PLACEHOLDERS.NORTH_WEST}
                        name={STRINGS.NORTH_WEST}
                        type="text"
                        disabled="true"
                        onChange={(e) => handleNW(e)}
                        value={`${
                          northEast
                            ? `${northEast?.latitude?.toPrecision(
                                10
                              )}, ${northEast?.longitude?.toPrecision(10)}`
                            : northEast
                        }`}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <TextField
                        label={LABELS.SOUTH_EAST}
                        placeholder={PLACEHOLDERS.SOUTH_EAST}
                        name={STRINGS.SOUTH_EAST}
                        type="text"
                        disabled="true"
                        onChange={(e) => handleSE(e)}
                        value={`${
                          southWest
                            ? `${southWest.latitude.toPrecision(
                                10
                              )}, ${southWest.longitude.toPrecision(10)}`
                            : southWest
                        }`}
                      />
                    </div>
                  </div> */}
                </div>
              </fieldset>

              <fieldset>
                <legend>{STRINGS.TRAIL_CATEGORY}</legend>
                <div className="form-row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>{STRINGS.TRAIL_CATEGORY_TYPE}</label>
                      <Multi_Select_Dropdown
                        name="Trail_Category"
                        options={category}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        changeHandler={(e) => {
                          props.setFieldValue("Trail_Category", e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>{STRINGS.TRAIL_SUITABILITY}</label>
                      <Multi_Select_Dropdown
                        name="Trail_Suitability"
                        options={filter_suitability}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        changeHandler={(e) => {
                          props.setFieldValue("Trail_Suitability", e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>{STRINGS.DIFFICULTY_LEVEL}</label>
                      <Dropdown
                        name="Difficulty_Level"
                        defaultValue=""
                        options={filter_difficulty}
                        placeholder={PLACEHOLDERS.SELECT}
                        changeHandler={(e) => {
                          props.setFieldValue("Difficulty_Level", e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend>{STRINGS.TRAIL_DISTANCE}</legend>
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <TextField
                        label={LABELS.MINIMUM_TIME}
                        placeholder={PLACEHOLDERS.MINIMUM_TIME}
                        name={STRINGS.MINIMUM_TIME}
                        type="text"
                        className="without_ampm"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <TextField
                        label={LABELS.MAXIMUM_TIME}
                        placeholder={PLACEHOLDERS.MAXIMUM_TIME}
                        name={STRINGS.MAXIMUM_TIME}
                        type="text"
                        className="without_ampm"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend>Activities</legend>
                <div className="d-flex active_tags">
                  {activities?.map((value, tagIndex) => (
                    <Tag
                      {...value}
                      tag_selected_style={tag_selected_style}
                      tag_unselected_style={tag_unselected_style}
                      tagIndex={tagIndex}
                      tag_clickHandler={tag_clickHandler}
                      is_deletable={false}
                    />
                  ))}
                </div>
              </fieldset>

              <fieldset>
                <legend>{STRINGS.TRACKING_INFO}</legend>
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <TextField
                        label={STRINGS.NEAREST_TOWN}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.NEAREST_TOWN}
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <TextField
                        label={STRINGS.TRAIL_START_POINT}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.TRAIL_START_POINT}
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <TextField
                        label={STRINGS.TERRAIN_TYPE}
                        placeholder={PLACEHOLDERS.WRITE_HERE}
                        name={STRINGS.TERRAIN_TYPE}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Trail Type</label>
                      <Multi_Select_Dropdown
                        name="Trail_Type"
                        options={filter_trail}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        changeHandler={(e) => {
                          props.setFieldValue("Trail_Type", e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{STRINGS.EQUIPMENT}</label>
                      <Multi_Select_Dropdown
                        name="Equipments"
                        options={current_Equipments}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        changeHandler={(e) => {
                          props.setFieldValue("Equipments", e);
                        }}
                      />
                      {/* {dropDownErrors.} */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>{STRINGS.WAYMARKING}</label>
                      <Dropdown
                        name="Difficulty_Level"
                        defaultValue=""
                        options={currentColorOptions}
                        placeholder={PLACEHOLDERS.SELECT}
                        changeHandler={(e) => {
                          props.setFieldValue(STRINGS.WAYMARKING, e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend>{STRINGS.OTHER_FACILITIES}</legend>
                {/* <div className="form-row"> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Nearby Attractions</label>
                    <Multi_Select_Dropdown
                      name="Nearby Attractions"
                      options={dropdownNearbyAttractions}
                      value={nearbyAttractionspoints}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      allowSelectAll={true}
                      changeHandler={(e, option) => {
                        setNearbyAttractionsPoints(e);
                        props.setFieldValue("Nearby Attractions", e);
                      }}
                    />
                  </div>
                </div>
                {nearbyAttractionspoints && nearbyAttractionspoints?.length
                  ? nearbyAttractionspoints.map((value) => (
                      <div>
                        <Card
                          Image={value.value.image}
                          Title={value.value.name}
                          isFile={false}
                        />
                      </div>
                    ))
                  : null}

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Bike Repair Points</label>
                    <Multi_Select_Dropdown
                      name={STRINGS.BIKE_REPAIR}
                      value={bikeRepairPoints}
                      options={dropdownBikeRepair}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      allowSelectAll={true}
                      changeHandler={(e, option) => {
                        setBikeRepairPoints(e);
                        props.setFieldValue(STRINGS.BIKE_REPAIR, e);
                      }}
                    />
                  </div>
                </div>
                {bikeRepairPoints && bikeRepairPoints?.length
                  ? bikeRepairPoints?.map((value) => (
                      <div>
                        <Card
                          Image={value.value?.image}
                          Title={value.value?.name}
                          isFile={false}
                        />
                      </div>
                    ))
                  : null}
                {/* <div className="form-row"> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Public Transport</label>
                    <Multi_Select_Dropdown
                      name="Public Transport"
                      options={dropDownPublicTransport}
                      value={publicTransportPoints}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      allowSelectAll={true}
                      changeHandler={(e, option) => {
                        setPublicTransportPoints(e);
                        props.setFieldValue("Public Transport", e);
                      }}
                    />
                  </div>
                </div>
                {publicTransportPoints && publicTransportPoints?.length
                  ? publicTransportPoints.map((value) => (
                      <div>
                        <Card
                          Image={value.value.image}
                          Title={value.value.name}
                          isFile={false}
                        />
                      </div>
                    ))
                  : null}

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Garages</label>
                    <Multi_Select_Dropdown
                      name="Garages"
                      value={garagePoints}
                      options={dropDownGarages}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      allowSelectAll={true}
                      changeHandler={(e) => {
                        setGaragePoints(e);
                        props.setFieldValue("Garages", e);
                      }}
                    />
                  </div>
                </div>
                {garagePoints && garagePoints?.length
                  ? garagePoints.map((value) => (
                      <div>
                        <Card
                          Image={value.value.image}
                          Title={value.value.name}
                          isFile={false}
                        />
                      </div>
                    ))
                  : null}
                {/* </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Emergency Numbers</label>
                    <Multi_Select_Dropdown
                      name="Emergency"
                      // value={garagePoints}
                      options={currentEmergencyNumbers}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      allowSelectAll={true}
                      changeHandler={(e) => {
                        setEmergencyNumber(e);
                        props.setFieldValue("Emergency", e);
                      }}
                    />
                  </div>
                </div>
              </fieldset>

              <div className="btn_group pb-3">
                <button
                  className="btn btn-md btn-cancel m-1"
                  type="button"
                  onClick={() => history.push(ROUTE_CONSTANTS.ADMIN_TRAILS)}
                >
                  Cancel
                </button>
                <button className="btn btn-md btn-primary m-1" type="submit">
                  Add Trail
                </button>
              </div>
            </Form>
          )}
        </Formik>
        
      </div>
    </>
  );
}

export default withSnackbar(Add_Trail);
