import { titleCase } from "Shared";
import "./tags.scss";
import { IMAGES } from "Shared";
import ServerImage from "../Server_Image";

const Tag = ({
  name = "",
  tagIndex,
  isSelected = false,
  tag_selected_style = {},
  tag_unselected_style = {},
  tag_clickHandler = () => { },
  delete_handler = () => { },
  is_deletable = false,
  suitability = "",
  data = "",
  imageUrl = "",
  toggleEditModalView = () => { }
}) => {
  let can_delete = is_deletable;

  return (
    <>
      <button
        type="button"
        className="block_tags"
        style={{
          ...(isSelected ? tag_selected_style : tag_unselected_style),
          border: "none",
        }}
        onClick={(e) => {
          tag_clickHandler(tagIndex);
        }}
      >

        <div className="file_pic">
          <ServerImage
            // defaultSrc={imageUrl}
            url={imageUrl}
            width="40px"
            height="40px"
            alt="No Image"
          />
        </div>

        {suitability ? <div className="edt_div"> <button type="button"
          className="btn"
          onClick={() => toggleEditModalView(data, tagIndex)}
        >
          <img src={IMAGES.EDIT_BUTTON} alt="Edit Icon" />
        </button></div> : " "}

        {titleCase(name)}

        <div className="del_div">
          {can_delete ? (
            <button className="btn"
              type="button"

              onClick={() => {
                delete_handler(tagIndex)
              }}
            ><img src={IMAGES.TRASH_ICON} alt="Remove Icon" /></button>
          ) : null}
        </div>

      </button>
    </>
  );
};

export default Tag;
