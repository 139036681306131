import { ROUTE_CONSTANTS } from "Shared/Routes";
import Add_Trail_Screen from "Views/Authenticated/Add_Trail/index";
import Stay_Eat from "Views/Authenticated/Stay/Stay_Eat";
import Add_Categories from "Views/Authenticated/Add_Categories";
import Add_Filters from "Views/Authenticated/Add_Filters";
import Add_Daysout from "Views/Authenticated/Add_Daysout"
import Trails from "Views/Authenticated/Trails";
import Card from "Components/atoms/Cards";
import Trail_Info from "Views/Authenticated/Trail_Info";
import Edit_Trail from "Views/Authenticated/Edit_Trail";
import Stay_Home from "Views/Authenticated/Stay_Home";
import Stay_Eat_Details from "Views/Authenticated/StayEat_Details";
import Days_Out_Home from "Views/Authenticated/Days_Out_Home";
import Days_Out_Details from "Views/Authenticated/Days_Out_Details";
import Edit_Days_Out from "Views/Authenticated/Edit_Daysout";
import Equipments from "Views/Authenticated/Equipments";
import Profile from "Views/Authenticated/Profile";
import Activity from "Views/Authenticated/Activity";
import Edit_StayEat from "Views/Authenticated/Edit_StayEat";
import Other_Facilities from "Views/Authenticated/Other_Facilities";
import Attractions_Nearby_Home from "Views/Authenticated/Attractions_Nearby_Home";
import Bike_Repair_Home from "Views/Authenticated/Bike_Repair_Home";
import Public_Transport_Home from "Views/Authenticated/Public_Transport_Home";
import Garages_Home from "Views/Authenticated/Garages_Home";
import Attractions_Nearby_Info from "Views/Authenticated/Attractions_Nearby_Info";
import Bike_Repair_Info from "Views/Authenticated/Bike_Repair_Info";
import Public_Transport_Info from "Views/Authenticated/Public_Transport_Info";
import Garages_Info from "Views/Authenticated/Garages_Info";
import Emergency_Numbers from "Views/Authenticated/Emergency_Numbers";
import Update_Other_Facilites from "Views/Authenticated/EditOtherFacilities"
import Advertisement from "Views/Authenticated/Advertisement";
import ViewUserActivity from "Views/Authenticated/Trail_View_UserActivity";



export const PRIVATE_ROUTES = [
  {
  path : ROUTE_CONSTANTS.EDIT_STAY_EAT,
  component: Edit_StayEat,
  title: " Edit Stay Eat"
  },
  {
    path: ROUTE_CONSTANTS.EDIT_DAYS_OUT,
    component: Edit_Days_Out,
    title: "Edit Days Out"
  },
  {
    path: ROUTE_CONSTANTS.ADMIN_ADD_TRAIL,
    component: Add_Trail_Screen,
    title: "Add Trail",
  },
  {
    path: ROUTE_CONSTANTS.ADMIN_ADD_STAY_OR_EAT,
    component: Stay_Eat,
    title: "Add Stay or Eat",
  },
  {
    path: ROUTE_CONSTANTS.ADMIN_ADD_CATEGORIES,
    component: Add_Categories,
    title: "Add Categories",
  },
  {
    path: ROUTE_CONSTANTS.ADMIN_ADD_FILTERS,
    component: Add_Filters,
    title: "Add Filters",
  },
  {
    path: ROUTE_CONSTANTS.ADMIN_ADD_DAYS_OUT,
    component: Add_Daysout,
    title: "Add DaysOut"
  },
  {
    path:ROUTE_CONSTANTS.ADMIN_TRAILS,
    component: Trails,
    title: "Trails"
  },
  {
    path:ROUTE_CONSTANTS.MAP,
    component: Card,
    title: "GpxMap"
  },
  {
    path:ROUTE_CONSTANTS.TRAIL_INFO,
    component:Trail_Info,
    title: "TrailInfo"
  },{
    path: ROUTE_CONSTANTS.EDIT_TRAIL,
    component:Edit_Trail,
    title: "EditTrail"
  },
  {
    path: ROUTE_CONSTANTS.VIEW_USERACTIVITY,
    component:ViewUserActivity,
    title: "ViewUserActivity"
  },
  {
    path: ROUTE_CONSTANTS.STAY_HOME,
    component:Stay_Home,
    title:"Stay Home"
  }
  ,
  {
    path: ROUTE_CONSTANTS.STAY_EAT_INFO,
    component:Stay_Eat_Details,
    title:"Stay Eat Details"
  },
  {
    path: ROUTE_CONSTANTS.DAYS_OUT_HOME,
    component: Days_Out_Home,
    title:"Days Out Home"
  }
  ,
  {
    path:ROUTE_CONSTANTS.DAYS_OUT_INFO,
    component: Days_Out_Details,
    title: "Days Out Details"
  },
  {
    path:ROUTE_CONSTANTS.EQUIPMENTS,
    component: Equipments,
    title: "Equipments"
  }
  ,{
    path: ROUTE_CONSTANTS.PROFILE,
    component: Profile,
    title: "Profile"
  },
  {
    path : ROUTE_CONSTANTS.ACTIVITY,
    component : Activity,
    title: "Activity"
  },
  {
    path : ROUTE_CONSTANTS.ADD_OTHER_FACILTITES,
    component : Other_Facilities,
    title: "Other Facilities"
  },
  {
  path: ROUTE_CONSTANTS.NEARBY_ATTRACTIONS_HOME,
  component: Attractions_Nearby_Home,
  title: "Attractions Nearby Home"
  },
  {
    path : ROUTE_CONSTANTS.BIKE_REPAIR_HOME,
    component : Bike_Repair_Home,
    title: "Bike Repair Home"
  },
  {
    path : ROUTE_CONSTANTS.PUBLIC_TRANSPORT_HOME,
    component : Public_Transport_Home,
    title: "Public Transport Home"
  },
  {
    path : ROUTE_CONSTANTS.GARAGES_HOME,
    component : Garages_Home,
    title : "Garages Home"
  },
  {
    path : ROUTE_CONSTANTS.NEARBY_ATTRACTIONS_INFO,
    component: Attractions_Nearby_Info,
    title: "Attraction Nearby Info"
  },
  {
    path : ROUTE_CONSTANTS.PUBLIC_TRANSPORT_INFO,
    component: Public_Transport_Info,
    title: "Public Transport Info"
  },
  {
    path : ROUTE_CONSTANTS.BIKE_REPAIR_INFO,
    component: Bike_Repair_Info,
    title: "Bike Repair Info"
  },
  {
    path : ROUTE_CONSTANTS.GARAGE_INFO,
    component: Garages_Info,
    title: "Garage Info"
  },
  {
    path : ROUTE_CONSTANTS.EMERGENCY_NUMBERS,
    component: Emergency_Numbers,
    title: "Emergency Numbers"
  }
  ,{
    path : ROUTE_CONSTANTS.EDIT_OTHER_FACILTIES,
    component: Update_Other_Facilites,
    title: "Update Other Facilities"
  }
  ,{
    path : ROUTE_CONSTANTS.ADVERTISEMENT,
    component: Advertisement,
    title: "Advertisement"
  }
];
