import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Capitalize, LABELS, ROUTE_CONSTANTS, STRINGS, titleCase } from "Shared";
import { withSnackbar } from "notistack";
import { deleteOtherFacilities, getOtherFacilities } from "Redux/Actions/commonCRUD";

function Garages_Info(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentGarage = useSelector(
    (state) => state.facilities.currentGarage
  );

  const currentFacility = useSelector((state) => state.facilities.currentFacility);
  const deleteHandler = (id) => {
    dispatch(
      deleteOtherFacilities(id, (message, type) => {
        props.enqueueSnackbar(message, {
          variant: type,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });

        if (type == "success") {
          history.push(ROUTE_CONSTANTS.GARAGES_HOME);
          dispatch(getOtherFacilities(2));
        }
      })
    );
  }
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap w-100 page-title">
        <h4 className="h4">Garage</h4>
        <div className="col_rht">
          <button className="btn btn-sm btn-primary"
            onClick={() => {
              history.push(ROUTE_CONSTANTS.EDIT_OTHER_FACILTIES)
            }}>Edit</button>
          <button
            className="btn btn-sm btn-cancel"
            onClick={() => deleteHandler(currentGarage?._id)}
          >
            Delete
          </button>
        </div>
      </div>
      <div>
        <>
          <div className="basic_info">
            <div className="photos_list">
              <div className="photo_items">
                <figure>
                  <img src={`https://${currentGarage?.imageUrl}`} />
                </figure>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="info_block">
                  <label>{STRINGS.NAME}</label>
                  <span>{Capitalize(currentGarage?.name)}</span>
                </div>
                <div className="info_block">
                  <label>{STRINGS.WEBSITE}</label>
                  <span>{currentGarage?.webLink ?? "---"} </span>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default withSnackbar(Garages_Info);
