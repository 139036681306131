import { LocalTaxiOutlined, NavigateBeforeSharp } from "@material-ui/icons";
import { useMemo, useRef, useState } from "react";
import "./style.css";
function GpxMap({ points,test=()=>{} ,test1, m1_cord, m2_cord, marker1=()=>{},marker2=()=>{} }) {

  const p1_data = useRef(null)
  const p2_data = useRef(null)
  let center = points?.length <= 2 ? null : parseInt(points?.length / 2);
  const map = new window.google.maps.Map(document.getElementById("map"), {
    zoom: 16,
    center: points[center] ?? points[0],
    mapTypeId: "terrain",
    showCompass: true,
  
  });

  const flightPlanCoordinates = points;
 
  const flightPath = new window.google.maps.Polyline({
    path: flightPlanCoordinates,
    geodesic: true,
    strokeColor: "#13651B",
    strokeOpacity: 1.0,
    strokeWeight: 2,
  });

  new window.google.maps.Marker({
    position: flightPlanCoordinates[0],
    label: "A",
    map,
  });

  new window.google.maps.Marker({
    position: flightPlanCoordinates[flightPlanCoordinates.length - 1],
    label: "B",
    map,
  });
 
  // let m1 = new window.google.maps.Marker({
  //   // position: {lat:41.878113,lng:-87.629799},
  //   // position: points[center+50],
  //   position: (m1_cord?.current?.latitude && m1_cord?.current?.longitude) ? {lat: m1_cord.current?.latitude, lng: m1_cord.current?.longitude} : {lat: flightPlanCoordinates[0]?.lat+0.0089, lng: flightPlanCoordinates[0]?.lng},
  //   label: "NE",  
  //   map,
  //   draggable:true,
  //   animation: window.google.maps.Animation.DROP
  // });
  
  // let m2 = new window.google.maps.Marker({
  //   // position: {lat:41.878113,lng:87.629799},
  //   // position: points[center-50],
  //   position: (m2_cord?.current?.latitude && m2_cord?.current?.longitude) ? {lat: m2_cord?.current?.latitude, lng: m2_cord?.current?.longitude} : {lat: flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lat, lng: flightPlanCoordinates[flightPlanCoordinates.length - 1]?.lng+0.0089},
  //   label: "SW",
  //   map,
  //   draggable:true,
  //   animation: window.google.maps.Animation.DROP
  // });
  
  // //  let infoWindow = new window.google.maps.InfoWindow({
  // //   content: "lat,lng",
  // //   // position: center,
  // // });
  
  // new window.google.maps.event.addListener(m1, "dragend",(event)=> {
  //   let lat, lng, address;
  //   // infoWindow.close();
  //   lat = m1.getPosition().lat()
  //   lng = m1.getPosition().lng()
  //   console.log(lat, lng, "lantlnadfasdf")
  //   p1_data.current = m1.getPosition()
  //   // m1_cord.current = m1.getPosition()
  //   // console.log("asfasefaee",lat1.current, lat2.current)
  //   test("asdfasd1f")
  //   marker1(m1.getPosition())
  //   console.log("wokring")
  //   let infoWindow = new window.google.maps.InfoWindow({
  //     content: `${m1.getPosition()}`
  //   })
  //   m1.addListener("click", ()=>{
      
  //     infoWindow.open({
  //       anchor: m1,
  //       map,
  //       shouldFocus: false
  //     })
  //     setTimeout(()=>{
  //       infoWindow.close();
  //     },1000)
  //   })
  //   // infoWindow.setContent(JSON.stringify(m1.getPosition().toJSON(), null, 2))
  //   // infoWindow.open(m1)
  // })

  // new window.google.maps.event.addListener(m2, "dragend",(event)=> {
  //   let lat, lng, address;
  //   lat = m2.getPosition().lat()
  //   lng = m2.getPosition().lng()
  //   // console.log(lat, lng, "lantlnadfasdf")
  //   p2_data.current = m2.getPosition()
  //   // m2_cord.current = m2.getPosition()
  //   marker2(m2.getPosition())
  //   // console.log()
    
  //   let infoWindow = new window.google.maps.InfoWindow({
  //     content: `${m2.getPosition()}`
  //   })
  //   m2.addListener("click", ()=>{
      
  //     infoWindow.open({
  //       anchor: m2,
  //       map,
  //       shouldFocus: false
  //     })
  //     setTimeout(()=>{
  //       infoWindow.close();
  //     },1000)
  //   })

  // })


  flightPath.setMap(map);
  return <></>;
}

export default GpxMap;
