import { GET_TRAILS, GET_USER_ACTIVITIES, SET_CURRENT_USERACTIVITY, SET_TRAILS, SET_USER_ACTIVITIES } from "../Actions/ActionType"

const initialState = {
    UserActivities: [],
    Current_UserActivity:{}
}

function userActivityReducer(state = initialState, action) {

    switch (action.type) {
        case GET_USER_ACTIVITIES:
            return state;

        case SET_USER_ACTIVITIES:
            return {
                ...state,
                UserActivities: action.payload
            }
        case SET_CURRENT_USERACTIVITY:
            return {
                ...state,
                Current_UserActivity:action.payload
            }

        default:
            return state;
    }
}

export default userActivityReducer;